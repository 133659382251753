import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_TOP_PANELS_REQUEST } from "constants/TopPanels";
import {
  fetchTopPanelsSuccess,
  fetchTopPanelsFailure,
} from "redux/actions/TopPanels";
import TopAgenciesService from "services/TopAgenciesService";

export function* onFetchTopPanels() {
  yield takeLatest(FETCH_TOP_PANELS_REQUEST, function* (payload) {
    try {
      const result = yield call(TopAgenciesService.index, payload.payload);
      if (result.status) {
        yield put(fetchTopPanelsSuccess(result.data));
      } else {
        yield put(fetchTopPanelsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchTopPanelsFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchTopPanels)]);
}
