import {
  FETCH_MACHINES_SUCCESS,
  FETCH_MACHINES_REQUEST,
  FETCH_MACHINES_FAILURE,
  UPDATE_MACHINE_REQUEST,
  UPDATE_MACHINE_SUCCESS,
  UPDATE_MACHINE_FAILURE,
  SHOW_MACHINE_EDIT_FORM,
  DELETE_MACHINE_REQUEST,
  DELETE_MACHINE_SUCCESS,
  DELETE_MACHINE_FAILURE,
  SET_MACHINE_VALIDATION_ERRORS,
  SHOW_MACHINE_NEW_FORM,
  CREATE_MACHINE_REQUEST,
  CREATE_MACHINE_SUCCESS,
  CREATE_MACHINE_FAILURE,
} from "constants/Machine";

import {
  addMachine,
  formtValidationErrors,
  removeMachine,
  updateMachine,
} from "utils/Machine";

const initState = {
  loading: false,
  updating: false,
  creating: false,
  list: [],
  errorMessage: "",
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const machine = (state = initState, action) => {
  switch (action.type) {
    case FETCH_MACHINES_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_MACHINES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_MACHINES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_MACHINE_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_MACHINE_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_MACHINE_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_MACHINE_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_MACHINE_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateMachine(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_MACHINE_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_MACHINE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_MACHINE_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeMachine(state, action),
      };
    case DELETE_MACHINE_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_MACHINE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_MACHINE_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addMachine(state, action),
      };
    case CREATE_MACHINE_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default machine;
