import {
  FETCH_CHECK_LISTS_SUCCESS,
  FETCH_CHECK_LISTS_REQUEST,
  FETCH_CHECK_LISTS_FAILURE,
  UPDATE_CHECK_LIST_REQUEST,
  UPDATE_CHECK_LIST_SUCCESS,
  UPDATE_CHECK_LIST_FAILURE,
  SHOW_CHECK_LIST_EDIT_FORM,
  DELETE_CHECK_LIST_REQUEST,
  DELETE_CHECK_LIST_SUCCESS,
  DELETE_CHECK_LIST_FAILURE,
  SET_CHECK_LIST_VALIDATION_ERRORS,
  SHOW_CHECK_LIST_NEW_FORM,
  CREATE_CHECK_LIST_REQUEST,
  CREATE_CHECK_LIST_SUCCESS,
  CREATE_CHECK_LIST_FAILURE,
} from "constants/CheckList";

import {
  addCheckList,
  formtValidationErrors,
  removeCheckList,
  updateCheckList,
} from "utils/CheckList";

const initState = {
  loading: false,
  updating: false,
  creating: false,
  list: [],
  errorMessage: "",
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const checkList = (state = initState, action) => {
  switch (action.type) {
    case FETCH_CHECK_LISTS_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_CHECK_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_CHECK_LISTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_CHECK_LIST_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_CHECK_LIST_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_CHECK_LIST_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_CHECK_LIST_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_CHECK_LIST_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateCheckList(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_CHECK_LIST_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_CHECK_LIST_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_CHECK_LIST_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeCheckList(state, action),
      };
    case DELETE_CHECK_LIST_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_CHECK_LIST_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_CHECK_LIST_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addCheckList(state, action),
      };
    case CREATE_CHECK_LIST_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default checkList;
