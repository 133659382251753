import {
  FETCH_PATIENTTESTRESULTHISTORY_SUCCESS,
  FETCH_PATIENTTESTRESULTHISTORY_REQUEST,
  FETCH_PATIENTTESTRESULTHISTORY_FAILURE,
  SHOW_NEW_ORDER_SEARCH_FORM,
  FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
  FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS,
  FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE

} from "constants/PatientTestResultHistory";

const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  showSearchForm:true,
  showNewForm:true,
  patientslist:[]
};

const patienttestresulthistory = (state = initState, action) => {
  switch (action.type) {
    case FETCH_PATIENTTESTRESULTHISTORY_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
        // showSearchForm:true,
        // list:[],
      };
    case FETCH_PATIENTTESTRESULTHISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_PATIENTTESTRESULTHISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        // showSearchForm: !state.showSearchForm,
        // list:[]
      };

      // case   SHOW_NEW_ORDER_SEARCH_FORM:
      //   return {
      //     ...state,
      //     showSearchForm: !state.showSearchForm,
      //     list:[]
      //   };

      case FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS:
        return {
          ...state,
          loading: false,
          patientslist: action.payload,
        };
      case FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };


    default:
      return state;
  }
};

export default patienttestresulthistory;
