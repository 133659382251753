import _ from "lodash";

export const addMaintenance = (state, action) => {
  let list = state.list;
  const savedMaiaddMaintenance = action.payload;

  return [savedMaiaddMaintenance, ...list];
};

export const removeMaintenance = (state, action) => {
  let list = state.list;
  const deletedMaiaddMaintenanceId = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedMaiaddMaintenanceId;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};
