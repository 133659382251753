export const SHOW_TOPAGENCIES_NEW_FORM = "SHOW_TOPAGENCIES_NEW_FORM";
export const SHOW_TOPAGENCIES_EDIT_FORM = "SHOW_TOPAGENCIES_EDIT_FORM";
export const SET_TOPAGENCIES_VALIDATION_ERRORS = "SET_TOPAGENCIES_VALIDATION_ERRORS";

export const FETCH_TOPAGENCIES_REQUEST = "FETCH_TOPAGENCIES_REQUEST";
export const FETCH_TOPAGENCIES_SUCCESS = "FETCH_TOPAGENCIES_SUCCESS";
export const FETCH_TOPAGENCIES_FAILURE = "FETCH_TOPAGENCIES_FAILURE";

export const UPDATE_TOPAGENCIES_REQUEST = "UPDATE_TOPAGENCIES_REQUEST";
export const UPDATE_TOPAGENCIES_SUCCESS = "UPDATE_TOPAGENCIES_SUCCESS";
export const UPDATE_TOPAGENCIES_FAILURE = "UPDATE_TOPAGENCIES_FAILURE";

export const DELETE_TOPAGENCIES_REQUEST = "DELETE_TOPAGENCIES_REQUEST";
export const DELETE_TOPAGENCIES_SUCCESS = "DELETE_TOPAGENCIES_SUCCESS";
export const DELETE_TOPAGENCIES_FAILURE = "DELETE_TOPAGENCIES_FAILURE";

export const CREATE_TOPAGENCIES_REQUEST = "CREATE_TOPAGENCIES_REQUEST";
export const CREATE_TOPAGENCIES_SUCCESS = "CREATE_TOPAGENCIES_SUCCESS";
export const CREATE_TOPAGENCIES_FAILURE = "CREATE_TOPAGENCIES_FAILURE";
