import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Role from "./Role";
import AgencyTests from "./AgencyTests";
import User from "./User";
import Coupon from "./Coupon";
import Section from "./Section";
import Machine from "./Machine";
import Equipment from "./Equipment";
import CheckList from "./CheckList";
import Maintenance from "./Maintenance";
import Department from "./Department";
import Appointment from "./Appointment";
import storage from "redux-persist/lib/storage";
import BranchLookUp from "./BranchLookUp";
import TestDirectory from "./TestDirectory";
import TestRegistration from "./TestRegistration";
import Patient from "./Patient";
import ClientCompany from "./ClientCompany";
import BranchTestCapacity from "./BranchTestCapacity";
import ClientCompanyTestDirectory from "./ClientCompanyTestDirectory";
import NewOrder from "./NewOrder";
import OrderMadeByGuestUsers from "./OrderMadeByGuestUsers";
import InPersonDelivery  from "./InPersonDeliver";
import Financials from "./Financials";
import { persistReducer } from "redux-persist";
import Notyetreleased from "./Notyetreleased";
import Released from "./Released";
import OMBGUSampleNotRecievedByICL from "./OMBGUSampleNotRecievedByICL";
import PatientResult from "./PatientResult";
import Discrepancy from "./Discrepancy";
import DiscrepancyApproved from "./DiscrepanciesStatus/DiscrepancyApproved";
import DiscrepancyDeclined from "./DiscrepanciesStatus/DiscrepancyDeclined";
import DiscrepancyPending from "./DiscrepanciesStatus/DiscrepancyPending";
import ApprovedOrders from "./OrderStatus/ApprovedOrders";
import DeclinedOrders from "./OrderStatus/DeclinedOrders";
import PendingOrders from "./OrderStatus/PendingOrders";
import AllOrders from "./OrderStatus/AllOrders";
import TopAgencies from "./TopAgencies";
import TopTests from "./TopTests";
import topPanels from "./TopPanels";
import TopBranchesForWalkinCustomers from "./TopBranchesForWalkinCustomer";
import PanicandAbnormalTestResults from "./PanicandAbnormalTestResults";
import PatientTestResultHistory from "./PatientTestResultHistory";
import PatientSearch from "./PatientsSearch";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["appointment", "branchLookUp"],
};

const reducers = combineReducers({
  theme: Theme,
  role: Role,
  agencyTests : AgencyTests,
  user: User,
  coupon: Coupon,
  section: Section,
  machine: Machine,
  equipment: Equipment,
  checkList: CheckList,
  maintenance: Maintenance,
  auth: Auth,
  department: Department,
  appointment: Appointment,
  branchLookUp: BranchLookUp,
  testDirectory: TestDirectory,
  testRegistration: TestRegistration,
  patient: Patient,
  branchTestCapacity: BranchTestCapacity,
  clientCompany: ClientCompany,
  clientCompanyTestDirectory: ClientCompanyTestDirectory,
  newOrder: NewOrder,
  orderMadeByGuestUsersState: OrderMadeByGuestUsers,
  inPersonDelivery : InPersonDelivery,
  financial: Financials,
  notyetreleaseds: Notyetreleased,
  releaseds: Released,
  OMBGUSampleNotRecievedByICL: OMBGUSampleNotRecievedByICL,
  patientresult: PatientResult,
  discrepancy: Discrepancy,
  discrepancyApproved : DiscrepancyApproved,
  discrepancyDeclined : DiscrepancyDeclined,
  discrepancyPending : DiscrepancyPending,
  approvedOrders : ApprovedOrders,
  declinedOrders : DeclinedOrders,
  pendingOrders : PendingOrders,
  allOrders : AllOrders,
  topagencies: TopAgencies,
  toptests: TopTests,
  topPanels: topPanels,
  topBranchesForWalkinCustomers: TopBranchesForWalkinCustomers,
  panicandabnormaltestresults: PanicandAbnormalTestResults,
  patienttestresulthistory: PatientTestResultHistory,
  patientSearch: PatientSearch,
});

export default persistReducer(persistConfig, reducers);
