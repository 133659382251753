import fetch from "auth/FetchInterceptor";

const ReleasedService = {};

ReleasedService.index = function (payload) {
  return fetch({
    url:
      "/reports/orders-all?" +
      "startdate=" +
      payload.startDate +
      "&enddate=" +
      payload.endDate +
      "&agency=" +
      payload.agency +
      "&department=" +
      payload.department,
    method: "get",
  });
};

export default ReleasedService;
