import {
  SET_VALIDATION_ERRORS,
  FETCH_EXISTING_PATIENT_REQUEST,
  FETCH_EXISTING_PATIENT_SUCCESS,
  FETCH_EXISTING_PATIENT_FAILURE,
  SHOW_SEARCH_FORM,
} from "constants/PatientSearch";

const initState = {
  loading: false,
  // list: [],
  patientslist: [],
  errorMessage: "",
  validationErrors: [],
  // showSearchForm:true,
  // patientData:"",
  // initialpatient:null,
};

const patientSearch = (state = initState, action) => {
  switch (action.type) {
      //existing patients
      case FETCH_EXISTING_PATIENT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_EXISTING_PATIENT_SUCCESS:
        return {
          ...state,
          loading: false,
          patientslist: action.payload,
        };
      case FETCH_EXISTING_PATIENT_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };

      //end existing patients

    // case SET_VALIDATION_ERRORS:
    //   return {
    //     ...state,
    //     validationErrors: formtValidationErrors(action.payload),
    //   };
   
      case   SHOW_SEARCH_FORM:
        return {
          ...state,
          showSearchForm: !state.showSearchForm,
        };


    default:
      return state;
  }
};

export default patientSearch;
