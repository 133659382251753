import { CURRENT_USER } from "redux/constants/Auth";
export const getTheCurrentUserPermissions = () => {
  const currentUser = getParsedCurrentUser();
  //const currentUser = ["view-testDirectories", "view-neworders",  "view-pending-discrepancies", "approve-discrepancy", "decline-discrepancy"];
  return currentUser?.permissions;
};

export const DoesThisUserHasAPermissionToThisComponent = (
  component_permission
) => {
  const user_permissions = getTheCurrentUserPermissions();
  const current_user_permission = user_permissions != undefined ? user_permissions.map( item =>{
     return item.permission_name;
  }) : [];
  return current_user_permission.length <= 0 ? false :  current_user_permission.includes(component_permission, 0);
};

export const getCurrentRoleName = () =>{
   return getParsedCurrentUser().name;
}

function getParsedCurrentUser()
{
   return JSON.parse(localStorage.getItem(CURRENT_USER));
}