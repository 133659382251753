import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_TOPAGENCIES_REQUEST } from "constants/TopAgencies";
import {
  fetchTopAgenciesFailure,
  fetchTopAgenciesSuccess,
} from "redux/actions";
import TopAgenciesService from "services/TopAgenciesService";

export function* onFetchTopAgencies() {
  yield takeLatest(FETCH_TOPAGENCIES_REQUEST, function* (payload) {
    try {
      const result = yield call(TopAgenciesService.index, payload.payload);
      if (result.status) {
        yield put(fetchTopAgenciesSuccess(result.data));
      } else {
        yield put(fetchTopAgenciesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchTopAgenciesFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchTopAgencies)]);
}
