export const SHOW_ORDER_STATUS_EDIT_FORM = "SHOW_ORDER_STATUS_EDIT_FORM";

export const FETCH_APPROVED_ORDER_REQUEST = "FETCH_APPROVED_ORDER_REQUEST";
export const FETCH_APPROVED_ORDER_SUCCESS = "FETCH_APPROVED_ORDER_SUCCESS";
export const FETCH_APPROVED_ORDER_FAILURE = "FETCH_APPROVED_ORDER_FAILURE";


export const FETCH_APPROVED_ORDER_OBX_REQUEST = "FETCH_APPROVED_ORDER_OBX_REQUEST";
export const FETCH_APPROVED_ORDER_OBX_SUCCESS = "FETCH_APPROVED_ORDER_OBX_SUCCESS";
export const FETCH_APPROVED_ORDER_OBX_FAILURE = "FETCH_APPROVED_ORDER_OBX_FAILURE";

export const UPDATE_APPROVED_ORDER_REQUEST = "UPDATE_APPROVED_ORDER_REQUEST";
export const UPDATE_APPROVED_ORDER_SUCCESS = "UPDATE_APPROVED_ORDER_SUCCESS";
export const UPDATE_APPROVED_ORDER_FAILURE = "UPDATE_APPROVED_ORDER_FAILURE";
