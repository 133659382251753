import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import {
  FETCH_FINANCIALS_REQUEST,
} from "constants/Financials";
import {
  fetchFinancialsFailure,
  fetchFinancialsSuccess,
} from "redux/actions";
import FinancialsService from "services/Financials";

export function* onFetchFinancials() {
  yield takeLatest(FETCH_FINANCIALS_REQUEST, function* (payload) {
    try {
      const result = yield call(FinancialsService.index, payload);
      if (result.status) {
        yield put(fetchFinancialsSuccess(result.data));
      } else {
        yield put(fetchFinancialsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchFinancialsFailure(error.message));
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(onFetchFinancials),
  ]);
}
