import {
  FETCH_COUPONS_SUCCESS,
  FETCH_COUPONS_REQUEST,
  FETCH_COUPONS_FAILURE,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
  UPDATE_COUPON_FAILURE,
  SHOW_COUPON_EDIT_FORM,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAILURE,
  SET_COUPON_VALIDATION_ERRORS,
  SHOW_COUPON_NEW_FORM,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_FAILURE,
  PRINT_COUPONS_REQUEST,
  PRINT_COUPONS_SUCCESS,
  PRINT_COUPONS_FAILURE,
  USE_COUPON_REQUEST,
  USE_COUPON_SUCCESS,
  USE_COUPON_FAILURE,
} from "constants/Coupon";

import {
  formtValidationErrors,
  removeCoupon,
  updateCoupon,
} from "utils/Coupon";

const initState = {
  loading: false,
  updating: false,
  creating: false,
  printing: false,
  using: false,
  list: [],
  errorMessage: "",
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const coupon = (state = initState, action) => {
  switch (action.type) {
    case FETCH_COUPONS_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_COUPONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_COUPONS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_COUPON_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_COUPON_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_COUPON_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_COUPON_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_COUPON_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateCoupon(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_COUPON_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_COUPON_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeCoupon(state, action),
      };
    case DELETE_COUPON_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_COUPON_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        creating: false,
        list: action.payload,
        showNewForm: !state.showNewForm,
      };
    case CREATE_COUPON_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
    case PRINT_COUPONS_REQUEST:
      return {
        ...state,
        printing: true,
      };
    case PRINT_COUPONS_SUCCESS:
      return {
        ...state,
        printing: false,
        list: action.payload,
      };
    case PRINT_COUPONS_FAILURE:
      return {
        ...state,
        printing: false,
        errorMessage: action.payload,
      };
    case USE_COUPON_REQUEST:
      return {
        ...state,
        using: true,
      };
    case USE_COUPON_SUCCESS:
      return {
        ...state,
        using: false,
        list: updateCoupon(state, action),
      };
    case USE_COUPON_FAILURE:
      return {
        ...state,
        using: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default coupon;
