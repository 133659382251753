import {
    FETCH_DISCREPANCY_FAILURE,
    FETCH_DISCREPANCY_REQUEST,
    FETCH_DISCREPANCY_SUCCESS,
  
    FETCH_DISCREPANCYOBX_FAILURE,
    FETCH_DISCREPANCYOBX_REQUEST,
    FETCH_DISCREPANCYOBX_SUCCESS,
  
    UPDATE_DISCREPANCY_REQUEST,
    UPDATE_DISCREPANCY_SUCCESS,
    UPDATE_DISCREPANCY_FAILURE,
  
  } from "constants/DiscrepanciesStatus/DiscrepancyApproved";
  
  export const fetchDiscrepancyRequest = (startDate, endDate, agency, department) => ({
    type: FETCH_DISCREPANCY_REQUEST,
    payload : {
       startDate, 
       endDate, 
       agency, 
       department
    }
  });
  
  export const fetchDiscrepancySuccess = (discrepancy) => ({
    type: FETCH_DISCREPANCY_SUCCESS,
    payload: discrepancy,
  });
  
  export const fetchDiscrepancyFailure = (error) => ({
    type: FETCH_DISCREPANCY_FAILURE,
    payload: error,
  });
  
  
  export const fetchDiscrepancyObxRequest = (payload) => ({
    type: FETCH_DISCREPANCYOBX_REQUEST,
    payload: payload,
  });
  
  export const fetchDiscrepancyObxSuccess = (discrepancyobx) => ({
    type: FETCH_DISCREPANCYOBX_SUCCESS,
    payload: discrepancyobx,
  });
  
  export const fetchDiscrepancyObxFailure = (error) => ({
    type: FETCH_DISCREPANCYOBX_FAILURE,
    payload: error,
  });
  
  
  
  export const updateDiscrepancyRequest = (discrepancyObx) => ({
    type: UPDATE_DISCREPANCY_REQUEST,
    payload: discrepancyObx,
    
  });
  
  export const updateDiscrepancySuccess = (discrepancyObx) => ({
    type: UPDATE_DISCREPANCY_SUCCESS,
    payload: discrepancyObx,
  });
  
  export const updateDiscrepancyFailure = (error) => ({
    type: UPDATE_DISCREPANCY_FAILURE,
    payload: error,
  });
  