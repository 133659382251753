import fetch from "auth/FetchInterceptor";

const AllOrders = {};

AllOrders.get = function (payload) {
  return fetch({
    url: "/reports/orders-all?" +
    "startdate=" +
    payload.startDate +
    "&enddate=" +
    payload.endDate +
    "&agency=" +
    payload.agency +
    "&department=" +
    payload.department,
    method: "get",
  });
};

AllOrders.getObx = function(payload = null){
  return fetch({
    url: "/reports/getOrderObx?visitUuid=" + payload,
    method: "get",
  })
}

AllOrders.update = function (data) {
  return fetch({
    url: "/update-order-status",
    method: "put",
    data,
  });
};


export default AllOrders;
