export const SHOW_ROLE_EDIT_FORM = "SHOW_ROLE_EDIT_FORM";
export const SHOW_ROLE_NEW_FORM = "SHOW_ROLE_NEW_FORM";
export const SET_ROLE_VALIDATION_ERRORS = "SET_ROLE_VALIDATION_ERRORS";

export const FETCH_ROLES_REQUEST = "FETCH_ROLES_REQUEST";
export const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
export const FETCH_ROLES_FAILURE = "FETCH_ROLES_FAILURE";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_FAILURE = "UPDATE_ROLE_FAILURE";


export const CREATE_ROLE_REQUEST = "CREATE_ROLE_REQUEST";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_FAILURE = "CREATE_ROLE_FAILURE";
