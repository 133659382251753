import {
  FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_FAILURE,
  FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_REQUEST,
  FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_SUCCESS,
} from "../../constants/OMBGUSampleNotRecievedByIcl";

export const fetchOMBGUSampleNotRecievedRequest = (
  startDate,
  endDate,
  agency,
  department
) => ({
  type: FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_REQUEST,
  payload: {
    startDate,
    endDate,
    agency,
    department,
  },
});

export const fetchOMBGUSampleNotRecievedSuccess = (payload) => {
  return {
    type: FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_SUCCESS,
    payload: payload,
  };
};

export const fetchOMBGUSampleNotRecievedFailure = (payload) => {
  return {
    type: FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_FAILURE,
    payload: payload,
  };
};
