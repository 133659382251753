export const SHOW_COUPON_NEW_FORM = "SHOW_COUPON_NEW_FORM";
export const SHOW_COUPON_EDIT_FORM = "SHOW_COUPON_EDIT_FORM";
export const SET_COUPON_VALIDATION_ERRORS = "SET_COUPON_VALIDATION_ERRORS";

export const FETCH_COUPONS_REQUEST = "FETCH_COUPONS_REQUEST";
export const FETCH_COUPONS_SUCCESS = "FETCH_COUPONS_SUCCESS";
export const FETCH_COUPONS_FAILURE = "FETCH_COUPONS_FAILURE";

export const UPDATE_COUPON_REQUEST = "UPDATE_COUPON_REQUEST";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_FAILURE = "UPDATE_COUPON_FAILURE";

export const DELETE_COUPON_REQUEST = "DELETE_COUPON_REQUEST";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_FAILURE = "DELETE_COUPON_FAILURE";

export const CREATE_COUPON_REQUEST = "CREATE_COUPON_REQUEST";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_FAILURE = "CREATE_COUPON_FAILURE";

export const PRINT_COUPONS_REQUEST = "PRINT_COUPONS_REQUEST";
export const PRINT_COUPONS_SUCCESS = "PRINT_COUPONS_SUCCESS";
export const PRINT_COUPONS_FAILURE = "PRINT_COUPONS_FAILURE";

export const USE_COUPON_REQUEST = "USE_COUPON_REQUEST";
export const USE_COUPON_SUCCESS = "USE_COUPON_SUCCESS";
export const USE_COUPON_FAILURE = "USE_COUPON_FAILURE";
