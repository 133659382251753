import {
  FETCH_PANICANDABNORMALTESTRESULTS_SUCCESS,
  FETCH_PANICANDABNORMALTESTRESULTS_REQUEST,
  FETCH_PANICANDABNORMALTESTRESULTS_FAILURE,
  UPDATE_PANICANDABNORMALTESTRESULTS_REQUEST,
  UPDATE_PANICANDABNORMALTESTRESULTS_SUCCESS,
  UPDATE_PANICANDABNORMALTESTRESULTS_FAILURE,
  SHOW_PANICANDABNORMALTESTRESULTS_EDIT_FORM,
  DELETE_PANICANDABNORMALTESTRESULTS_REQUEST,
  DELETE_PANICANDABNORMALTESTRESULTS_SUCCESS,
  DELETE_PANICANDABNORMALTESTRESULTS_FAILURE,
  SET_PANICANDABNORMALTESTRESULTS_VALIDATION_ERRORS,
  SHOW_PANICANDABNORMALTESTRESULTS_NEW_FORM,
  CREATE_PANICANDABNORMALTESTRESULTS_REQUEST,
  CREATE_PANICANDABNORMALTESTRESULTS_SUCCESS,
  CREATE_PANICANDABNORMALTESTRESULTS_FAILURE,
} from "constants/PanicandAbnormalTestResults";

import {changeDateToHumanReadableFormat} from "utils/DateTimeUtil"
const initState = {
  loading: false,
  list: [],
  errorMessage: "",
};

const panicandabnormaltestresults = (state = initState, action) => {
  switch (action.type) {
    case FETCH_PANICANDABNORMALTESTRESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_PANICANDABNORMALTESTRESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: changeDateToHumanReadableFormat(action.payload),
      };
    case FETCH_PANICANDABNORMALTESTRESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default panicandabnormaltestresults;
