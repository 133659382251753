import Loading from "components/shared-components/Loading";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router";
import { CURRENT_USER } from "redux/constants/Auth";
import PageNotFoundPage from "views/PageNotFound";
import UnAuthorizedPage from "views/UnAuthorized";
import {ROLE_GUEST, ROLE_QUALITY_CONTROL } from "configs/AppConfig";
import { DoesThisUserHasAPermissionToThisComponent } from "utils/PermissionChecking";
import { 
  VIEW_AGENCIES_TESTS,
  VIEW_ALL_DISCREPANCIES,
  VIEW_ALL_ORDERS,
  VIEW_ALL_RELEASED_RESULTS,
  VIEW_APPROVED_DESCREPANCIES,
  VIEW_BRANCHLOOKUPS,
  VIEW_BRANCH_TEST_CAPACITIES,
  VIEW_CLIENTCOMPANIES,
  VIEW_CLIENTCOMPANYTESTDIRECTORIES,
  VIEW_COUPONS,
  VIEW_DASHBOARD,
  VIEW_DECLINED_DESCREPANCIES,
  VIEW_DEPARTMENTS,
  VIEW_FINANCIAL,
  VIEW_INPERSON_RESULTS,
  VIEW_NEWORDERS,
  VIEW_NOT_RELEASED_ORDERS,
  VIEW_PANIC_AND_ABNORMAL_TESTS,
  VIEW_PANEL_OBR,
  VIEW_PATIENT_RESULT_HISTORY,
  VIEW_PENDING_DISCREPANCIES,
  VIEW_REPORTS,
  VIEW_RESULTS_FOR_GUEST_USERS,
  VIEW_ROLES,
  VIEW_SAMPLE_NOT_RECIEVED_ORDERS,
  VIEW_TESTDIRECTORIES,
  VIEW_TOP_AGENCIES,
  VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS,
  VIEW_TOP_TESTS,
  VIEW_USERS,
  VIEW_RELEASED_PANEL_FOR_ICL
} from "configs/UserPermissions";
const AdminViews = () => {
  const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
  const role = currentUser?.role;
  return (
    <div>
      <Suspense fallback={<Loading cover="content" />}>
{role != ROLE_QUALITY_CONTROL && role != ROLE_GUEST ? (
  <Switch>
   {
     (DoesThisUserHasAPermissionToThisComponent(VIEW_DASHBOARD) &&
     <Route
      exact
      path={`${ADMIN_PREFIX_PATH}`}
      component={lazy(() => import(`./dashboard/`))}
    />)
   }

    {
      (DoesThisUserHasAPermissionToThisComponent(VIEW_ROLES) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/roles`}
      component={lazy(() => import(`./role/list`))}
    />)
    }
    {
      (DoesThisUserHasAPermissionToThisComponent(VIEW_USERS) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/users`}
      component={lazy(() => import(`./user/list`))}
    />
    )
    }
    {
      (DoesThisUserHasAPermissionToThisComponent(VIEW_ROLES) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/agency-tests`}
      component={lazy(() => import(`./agencies-test/list`))}
    />
    )
    }
    {
      (DoesThisUserHasAPermissionToThisComponent(VIEW_COUPONS) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/coupons`}
      component={lazy(() => import(`./coupon/list`))}
    />
    )
    }
    {
      (DoesThisUserHasAPermissionToThisComponent(VIEW_DEPARTMENTS) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/departments`}
      component={lazy(() => import(`./department/list`))}
    />
     )
    }
    {
      (DoesThisUserHasAPermissionToThisComponent(VIEW_NEWORDERS) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/neworder`}
      component={lazy(() => import(`./neworder/neworder`))}
    />
    )
    }
    {
      (DoesThisUserHasAPermissionToThisComponent(VIEW_RESULTS_FOR_GUEST_USERS) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/ordermadebyguestusers`}
      component={lazy(() => import(`./released-results/ordermadebyguestusers/list`))}
    />
    )
    }
    {
      (DoesThisUserHasAPermissionToThisComponent(VIEW_DASHBOARD) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/dashboard2`}
      component={lazy(() => import(`./dashboard2`))}
      />
    )
    }
    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_FINANCIAL) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/financial`}
      component={lazy(() => import(`./financial/list`))}
    />
     )
    }
    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/approved-orders`}
      component={lazy(() => import(`./orders/approved-orders/list`))}
    />
     )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/pending-orders`}
      component={lazy(() => import(`./orders/pending-orders/list`))}
    />
     )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/declined-orders`}
      component={lazy(() => import(`./orders/declined-orders/list`))}
    />
     )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/all-orders`}
      component={lazy(() => import(`./orders/all-orders/list`))}
    />
     )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_RELEASED_PANEL_FOR_ICL) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/released-panels`}
      component={lazy(() => import(`./orders/released-orders/list`))}
    />
     )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_RELEASED_RESULTS) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/patient-result`}
      component={lazy(() => import(`./released-results/patient-result/list`))}
    />
     )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_PANIC_AND_ABNORMAL_TESTS) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/panicandabnormaltests`}
      component={lazy(() => import(`./released-results/panicandabnormaltests/list`))}
    />
     )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_INPERSON_RESULTS) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/in-person-result-delivery`}
      component={lazy(() => import(`./released-results/in-person-result-delivery/list`))}
    />
     )
    }

     {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_PATIENT_RESULT_HISTORY) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/patienttestresulthistory`}
      component={lazy(() => import(`./released-results/patienttestresulthistory/list`))}
    />
     )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_DISCREPANCIES) &&
      <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/discrepancy/all`}
      component={lazy(() => import(`./discrepancy/all/list`))}
    />
    )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_DESCREPANCIES) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-approved`}
      component={lazy(() =>
        import(`./discrepancy/discrepancy-approved`)
      )}
    />
    )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_DISCREPANCIES) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-pending`}
      component={lazy(() =>
        import(`./discrepancy/discrepancy-pending`)
      )}
    />
     )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_DECLINED_DESCREPANCIES) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-declined`}
      component={lazy(() =>
        import(`./discrepancy/discrepancy-declined`)
      )}
    />
    )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_AGENCIES) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/top-agencies`}
      component={lazy(() => import(`./topagencies/list`))}
    />
    )
    }
    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_TESTS) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/top-tests`}
      component={lazy(() => import(`./toptests/list`))}
    />
     )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_PANEL_OBR) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/top-panels`}
      component={lazy(() => import(`./top-panels/list`))}
    />
     )
    }

    
    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/top-branchs-for-walkin-customers`}
      component={lazy(() =>
        import(`./top-branchs-for-walkin-customers/list`)
      )}
    />
     )
    }
    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_NOT_RELEASED_ORDERS) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/not-yet-released`}
      component={lazy(() => import(`./orders/Notyetreleased/list`))}
    />
    )
    }
    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_SAMPLE_NOT_RECIEVED_ORDERS) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/not-yet-recieved`}
      component={lazy(() =>
        import(`./orders/OrderByGuestUsersSampleNotRecieved/list`)
      )}
    />
     )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_REPORTS) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/reports`}
      component={lazy(() => import(`./reports/list`))}
    />
    )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_TESTDIRECTORIES) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/test-directories`}
      component={lazy(() => import(`./test-directory/list`))}
    />
     )
    }

   {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCHLOOKUPS) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/branch-look-ups`}
      component={lazy(() => import(`./branch-look-up/list`))}
    />
    )
    }
    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCH_TEST_CAPACITIES) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/branch-test-capacities`}
      component={lazy(() => import(`./branch-test-capacity/list`))}
    />
    )
    }

    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_CLIENTCOMPANIES) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/client-companies`}
      component={lazy(() => import(`./client-company/list`))}
    />
     )
    }
   
    {
    (DoesThisUserHasAPermissionToThisComponent(VIEW_CLIENTCOMPANYTESTDIRECTORIES) &&
    <Route
      exact
      path={`${ADMIN_PREFIX_PATH}/client-company-test-directories`}
      component={lazy(() =>
        import(`./client-company-test-directory/list`)
      )}
    />
     )
    }

    <Route path="*">
      <PageNotFoundPage />
    </Route>
  </Switch>
) : (
  <UnAuthorizedPage />
)}
      </Suspense>
    </div>
  );
};

export default AdminViews;
