import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_PANICANDABNORMALTESTRESULTS_REQUEST } from "constants/PanicandAbnormalTestResults";
import {
  fetchPanicandAbnormalTestResultsFailure,
  fetchPanicandAbnormalTestResultsSuccess,
} from "redux/actions";
import PanicandAbnormalTestResultsService from "services/PanicandAbnormalTestResultsService";

// export function* onFetchPanicandAbnormalTestResults() {
//   yield takeLatest(FETCH_PANICANDABNORMALTESTRESULTS_REQUEST, function* ({payload}) {
//     try {
//       const result = yield call(PanicandAbnormalTestResultsService.index);
//       if (result.status) {
//         yield put(fetchPanicandAbnormalTestResultsSuccess(result.data));
//       } else {
//         yield put(fetchPanicandAbnormalTestResultsFailure(result.message));
//       }
//     } catch (error) {
//       yield put(fetchPanicandAbnormalTestResultsFailure(error.message));
//     }
//   });
// }

export function* onFetchPanicandAbnormalTestResults() {
  yield takeLatest(
    FETCH_PANICANDABNORMALTESTRESULTS_REQUEST,
    function* ({ payload }) {
      try {
        const result = yield call(
          PanicandAbnormalTestResultsService.index,
          payload
        );
        if (result.status & (result.data.length < 1)) {
          yield put(fetchPanicandAbnormalTestResultsFailure(result.message));
        }
        if (result.status & (result.data.length > 0)) {
          yield put(fetchPanicandAbnormalTestResultsSuccess(result.data));
        } else {
          yield put(fetchPanicandAbnormalTestResultsFailure(result.message));
        }
      } catch (error) {
        yield put(fetchPanicandAbnormalTestResultsFailure(error.message));
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(onFetchPanicandAbnormalTestResults)]);
}
