import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_REQUEST,
  FETCH_DEPARTMENTS_REQUEST,
  UPDATE_DEPARTMENT_REQUEST,
} from "constants/Department";
import {
  createDepartmentFailure,
  createDepartmentSuccess,
  deleteDepartmentFailure,
  deleteDepartmentSuccess,
  fetchDepartmentsFailure,
  fetchDepartmentsSuccess,
  setDepartmentValidationErrors,
  updateDepartmentFailure,
  updateDepartmentSuccess,
} from "redux/actions";
import DepartmentService from "services/Department";

export function* onFetchDepartments() {
  yield takeLatest(FETCH_DEPARTMENTS_REQUEST, function* () {
    try {
      const result = yield call(DepartmentService.index);
      if (result.status) {
        yield put(fetchDepartmentsSuccess(result.data));
      } else {
        yield put(fetchDepartmentsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchDepartmentsFailure(error.message));
    }
  });
}

export function* onUpdateDepartment() {
  yield takeLatest(UPDATE_DEPARTMENT_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        DepartmentService.update,
        payload.id,
        payload
      );
      if (result.status) {
        message.success("Updated");
        yield put(updateDepartmentSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setDepartmentValidationErrors(result.data.errors));
        yield put(updateDepartmentFailure(result.message));
      } else {
        yield put(updateDepartmentFailure(result.message));
      }
    } catch (error) {
      yield put(updateDepartmentFailure(error.message));
    }
  });
}

export function* onDeleteDepartment() {
  yield takeLatest(DELETE_DEPARTMENT_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(DepartmentService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteDepartmentSuccess(result.data));
      } else {
        yield put(deleteDepartmentFailure(result.message));
      }
    } catch (error) {
      yield put(deleteDepartmentFailure(error.message));
    }
  });
}

export function* onCreateDepartment() {
  yield takeLatest(CREATE_DEPARTMENT_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(DepartmentService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createDepartmentSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setDepartmentValidationErrors(result.data.errors));
        yield put(createDepartmentFailure(result.message));
      } else {
        yield put(createDepartmentFailure(result.message));
      }
    } catch (error) {
      yield put(deleteDepartmentFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchDepartments),
    fork(onUpdateDepartment),
    fork(onDeleteDepartment),
    fork(onCreateDepartment),
  ]);
}
