import {
  SET_VALIDATION_ERRORS,
  FETCH_EXISTING_PATIENT_REQUEST,
  FETCH_EXISTING_PATIENT_SUCCESS,
  FETCH_EXISTING_PATIENT_FAILURE,

} from "constants/PatientSearch";



//patients
export const fetchPatientSearchRequest = (phonenumber,sex,patientid,sampleid) => ({
  type: FETCH_EXISTING_PATIENT_REQUEST,
  payload:{
    phonenumber,sex,patientid,sampleid
  }
});

export const fetchPatientSearchSuccess = (newOrderPatient) => ({
  type: FETCH_EXISTING_PATIENT_SUCCESS,
  payload:  newOrderPatient,
});

export const fetchPatientSearchFailure = (error) => ({
  type: FETCH_EXISTING_PATIENT_FAILURE,
  payload: error,
});


//end patients

// export const togglePatientsearchForm = () => ({
//   type: SHOW_SEARCH_FORM,
// });
