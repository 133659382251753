import {
  FETCH_FINANCIALS_SUCCESS,
  FETCH_FINANCIALS_REQUEST,
  FETCH_FINANCIALS_FAILURE,
} from "constants/Financials";


const initState = {
  loading: false,
  list: [],
  errorMessage: "",
};

const financial = (state = initState, action) => {
  switch (action.type) {
    case FETCH_FINANCIALS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FINANCIALS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_FINANCIALS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

   
    default:
      return state;
  }
};

export default financial;
