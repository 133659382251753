import{
    FETCH_ALL_ORDER_REQUEST,
    FETCH_ALL_ORDER_SUCCESS,
    FETCH_ALL_ORDER_FAILURE,
    FETCH_ORDER_OBX_REQUEST,
    FETCH_ORDER_OBX_SUCCESS,
    FETCH_ORDER_OBX_FAILURE,
    UPDATE_ALL_ORDER_REQUEST,
    UPDATE_ALL_ORDER_FAILURE,
    UPDATE_ALL_ORDER_SUCCESS
} from "constants/OrderStatus/all-orders"

export const fetchAllOrderRequest = (startDate, endDate, agency, department) =>{
     return {
         type : FETCH_ALL_ORDER_REQUEST,
         payload : {    
            startDate, 
            endDate,
            agency, 
            department
         }
     }
}

export const fetchAllOrderSuccess = (payload) =>{ 
    return { 
        type : FETCH_ALL_ORDER_SUCCESS,
        payload : payload
    }
}

export const fetchAllOrderFailure = (payload) => { 
    return {
         type : FETCH_ALL_ORDER_FAILURE,
         payload : payload
    }
}

export const fetchOrderObxRequest = (visitUuid) =>{
    return {
        type : FETCH_ORDER_OBX_REQUEST,
        payload : {
           visitUuid
        }
    }
}

export const fetchOrderObxSuccess = (payload) =>{ 
   return { 
       type : FETCH_ORDER_OBX_SUCCESS,
       payload : payload
   }
}

export const fetchOrderObxFailure = (payload) => { 
   return {
        type : FETCH_ORDER_OBX_FAILURE,
        payload : payload
   }
}

export const updateAllOrdersRequest = (payload) =>{
    return { 
        type : UPDATE_ALL_ORDER_REQUEST,
        payload : payload
    }
}

export const updateAllOrdersSuccess = (payload) =>{ 
    return { 
        type : UPDATE_ALL_ORDER_SUCCESS,
        payload : payload
    }
}

export const updateAllOrdersFailure = (payload) =>{
     return { 
        type : UPDATE_ALL_ORDER_FAILURE,
        payload : payload
     }
}