import{
    FETCH_DECLINED_ORDER_FAILURE,
    FETCH_DECLINED_ORDER_REQUEST,
    FETCH_DECLINED_ORDER_SUCCESS,
    FETCH_DECLINED_ORDER_OBX_REQUEST,
    FETCH_DECLINED_ORDER_OBX_SUCCESS,
    FETCH_DECLINED_ORDER_OBX_FAILURE,
    UPDATE_DECLINED_ORDER_REQUEST,
    UPDATE_DECLINED_ORDER_FAILURE,
    UPDATE_DECLINED_ORDER_SUCCESS
    } from "constants/OrderStatus/DeclinedOrders"
    
    export const fetchDeclinedOrderRequest = (startDate, endDate, agency, department) =>{
         return {
             type : FETCH_DECLINED_ORDER_REQUEST,
             payload : {
                startDate, 
                endDate,
                agency, 
                department
             }
         }
    }
    
    export const fetchDeclinedOrderSuccess = (payload) =>{ 
        return { 
            type : FETCH_DECLINED_ORDER_SUCCESS,
            payload : payload
        }
    }
    
    export const fetchDeclinedOrderFailure = (payload) => { 
        return {
             type : FETCH_DECLINED_ORDER_FAILURE,
             payload : payload
        }
    }
    
    export const fetchDeclinedOrderObxRequest = (visitUuid) =>{
        return {
            type : FETCH_DECLINED_ORDER_OBX_REQUEST,
            payload : {
               visitUuid
            }
        }
    }
    
    export const fetchDeclinedOrderObxSuccess = (payload) =>{ 
       return { 
           type : FETCH_DECLINED_ORDER_OBX_SUCCESS,
           payload : payload
       }
    }
    
    export const fetchDeclinedOrderObxFailure = (payload) => { 
       return {
            type : FETCH_DECLINED_ORDER_OBX_FAILURE,
            payload : payload
       }
    }

    export const updateDeclinedOrderRequest = (payload) =>{
        return { 
            type : UPDATE_DECLINED_ORDER_REQUEST,
            payload : payload
        }
    }
    
    export const updateDeclinedOrderFailure = (payload) =>{ 
        return { 
            type : UPDATE_DECLINED_ORDER_FAILURE,
            payload : payload
        }
    }
    
    export const updateDeclinedOrderSuccess = (payload) =>{
         return { 
            type : UPDATE_DECLINED_ORDER_SUCCESS,
            payload : payload
         }
    }