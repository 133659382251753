import fetch from "auth/FetchInterceptor";

const FinancialsService = {};

FinancialsService.index = function (payload) {
  return fetch({
    url: "/reports/financial",
    method: "get",
    data : payload
  });
};


export default FinancialsService;
