import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_BRANCH_TEST_CAPACITY_REQUEST,
  DELETE_BRANCH_TEST_CAPACITY_REQUEST,
  FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_REQUEST,
  FETCH_BRANCH_TEST_CAPACITIES_REQUEST,
  UPDATE_BRANCH_TEST_CAPACITY_REQUEST,
} from "constants/BranchTestCapacity";
import {
  createBranchTestCapacityFailure,
  createBranchTestCapacitySuccess,
  deleteBranchTestCapacityFailure,
  deleteBranchTestCapacitySuccess,
  fetchAvailableBranchTestCapacitiesFailure,
  fetchAvailableBranchTestCapacitiesSuccess,
  fetchBranchTestCapacitiesFailure,
  fetchBranchTestCapacitiesSuccess,
  setBranchTestCapacityValidationErrors,
  updateBranchTestCapacityFailure,
  updateBranchTestCapacitySuccess,
} from "redux/actions";
import BranchTestCapacityService from "services/BranchTestCapacity";

export function* onFetchBranchTestCapacity() {
  yield takeLatest(FETCH_BRANCH_TEST_CAPACITIES_REQUEST, function* () {
    try {
      const result = yield call(BranchTestCapacityService.index);
      if (result.status) {
        yield put(fetchBranchTestCapacitiesSuccess(result.data));
      } else {
        yield put(fetchBranchTestCapacitiesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchBranchTestCapacitiesFailure(error.message));
    }
  });
}

export function* onUpdateBranchTestCapacity() {
  yield takeLatest(UPDATE_BRANCH_TEST_CAPACITY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        BranchTestCapacityService.update,
        payload.id,
        payload
      );
      if (result.status) {
        message.success("Updated");
        yield put(updateBranchTestCapacitySuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setBranchTestCapacityValidationErrors(result.data.errors));
        yield put(updateBranchTestCapacityFailure(result.message));
      } else {
        yield put(updateBranchTestCapacityFailure(result.message));
      }
    } catch (error) {
      yield put(updateBranchTestCapacityFailure(error.message));
    }
  });
}

export function* onDeleteBranchTestCapacity() {
  yield takeLatest(DELETE_BRANCH_TEST_CAPACITY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(BranchTestCapacityService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteBranchTestCapacitySuccess(result.data));
      } else {
        yield put(deleteBranchTestCapacityFailure(result.message));
      }
    } catch (error) {
      yield put(deleteBranchTestCapacityFailure(error.message));
    }
  });
}

export function* onCreateBranchTestCapacity() {
  yield takeLatest(CREATE_BRANCH_TEST_CAPACITY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(BranchTestCapacityService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createBranchTestCapacitySuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setBranchTestCapacityValidationErrors(result.data.errors));
        yield put(createBranchTestCapacityFailure(result.message));
      } else {
        yield put(createBranchTestCapacityFailure(result.message));
      }
    } catch (error) {
      yield put(deleteBranchTestCapacityFailure(error.message));
    }
  });
}

export function* onFetchAvialbleBranchTestCapacities() {
  yield takeLatest(FETCH_AVAILABLE_BRANCH_TEST_CAPACITIES_REQUEST, function* ({payload}) {
    try {
      const result = yield call(BranchTestCapacityService.available, payload);
      if (result.status) {
        yield put(fetchAvailableBranchTestCapacitiesSuccess(result.data));
      } else {
        yield put(fetchAvailableBranchTestCapacitiesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchAvailableBranchTestCapacitiesFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchBranchTestCapacity),
    fork(onUpdateBranchTestCapacity),
    fork(onDeleteBranchTestCapacity),
    fork(onCreateBranchTestCapacity),
    fork(onFetchAvialbleBranchTestCapacities),
  ]);
}
