import {
    FETCH_INPERSON_DELIVERY_FAILURE,
    FETCH_INPERSON_DELIVERY_REQUEST,
    FETCH_INPERSON_DELIVERY_SUCCESS
} from "constants/InPersonDelivery"

export const fetchInPersonDeliveryRequest = (startDate, endDate, agency, department) =>{
    return {
         type : FETCH_INPERSON_DELIVERY_REQUEST,
         payload : { 
            startDate, 
            endDate, 
            agency, 
            department
         }
    }
}
export const fetchInPersonDeliverySuccess = (payload) =>{
    return {
         type : FETCH_INPERSON_DELIVERY_SUCCESS,
         payload :payload
    }
}

export const fetchInPersonDeliveryFailure = (payload) =>{
    return {
         type : FETCH_INPERSON_DELIVERY_FAILURE,
         payload : payload
    }
}