export const SHOW_DEPARTMENT_NEW_FORM = "SHOW_DEPARTMENT_NEW_FORM";
export const SHOW_DEPARTMENT_EDIT_FORM = "SHOW_DEPARTMENT_EDIT_FORM";
export const SET_DEPARTMENT_VALIDATION_ERRORS = "SET_DEPARTMENT_VALIDATION_ERRORS";

export const FETCH_DEPARTMENTS_REQUEST = "FETCH_DEPARTMENTS_REQUEST";
export const FETCH_DEPARTMENTS_SUCCESS = "FETCH_DEPARTMENTS_SUCCESS";
export const FETCH_DEPARTMENTS_FAILURE = "FETCH_DEPARTMENTS_FAILURE";

export const UPDATE_DEPARTMENT_REQUEST = "UPDATE_DEPARTMENT_REQUEST";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILURE = "UPDATE_DEPARTMENT_FAILURE";

export const DELETE_DEPARTMENT_REQUEST = "DELETE_DEPARTMENT_REQUEST";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILURE = "DELETE_DEPARTMENT_FAILURE";

export const CREATE_DEPARTMENT_REQUEST = "CREATE_DEPARTMENT_REQUEST";
export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_FAILURE = "CREATE_DEPARTMENT_FAILURE";
