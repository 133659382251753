const dev = {
  API_ENDPOINT_URL: 'http://127.0.0.1:8000/api',
  API_BASE_URL : 'https://127.0.0.1:8000/api',
  PDF_API_BASE_URL : 'http://127.0.0.1:8000'

};

const prod = {

  API_ENDPOINT_URL:'https://iclapi.dev.kerodapps.com/api',
  API_BASE_URL:'https://iclapi.dev.kerodapps.com/api',
  PDF_API_BASE_URL:'https://iclapi.dev.kerodapps.com/api'

};

const test = {

  API_ENDPOINT_URL:'https://api.icladdis.com/api',
  API_BASE_URL:'https://api.icladdis.com/api',
  PDF_API_BASE_URL:'https://api.icladdis.com'

};

const getEnv = () => {

	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}

}

export const env = getEnv()
