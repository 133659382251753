import Loading from "components/shared-components/Loading";
import { QC_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import HomePage from "./home";
import { Route, Switch } from "react-router";
import { CURRENT_USER } from "redux/constants/Auth";
import PageNotFoundPage from "views/PageNotFound";
import UnAuthorizedPage from "views/UnAuthorized";
 
const QualityControlViews = () => {
  const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
  const role = currentUser?.role;
  return (
    <div>
      <Suspense fallback={<Loading cover="content" />}>
      { 
         role === "qc-admin" ? 
         <Switch>
            <Route 
             exact
             path={`${QC_PREFIX_PATH}`}
            >
            <HomePage/>
         </Route>
             
          <Route
            exact
            path={`${QC_PREFIX_PATH}/sections`}
            component={lazy(() => import(`./section/list`))}
          />
           <Route
            exact
            path={`${QC_PREFIX_PATH}/machines`}
            component={lazy(() => import(`./machine/list`))}
          />
           <Route
            exact
            path={`${QC_PREFIX_PATH}/equipment`}
            component={lazy(() => import(`./equipment/list`))}
          />
           <Route
            exact
            path={`${QC_PREFIX_PATH}/check-lists`}
            component={lazy(() => import(`./check-list/list`))}
          />
          <Route
            exact
            path={`${QC_PREFIX_PATH}/maintenances`}
            component={lazy(() => import(`./maintenance/list`))}
          />
          <Route
            path="*">
                <PageNotFoundPage/>
            </Route>

        </Switch> : 
       <UnAuthorizedPage/>
      }
      </Suspense>
    </div>
  );
};

export default QualityControlViews;
