import React, { useState } from 'react';
import { Menu, Dropdown, Badge, Avatar, List, Button } from 'antd';
import { 
  BellOutlined, UserOutlined} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex'
import { connect } from 'react-redux';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { Link } from 'react-router-dom';



const getNotificationBody = list => {
  return list.length > 0 ?
  <List
    size="small"
    itemLayout="horizontal"
    dataSource={list}
    renderItem={appointment => (
      <List.Item className="list-clickable">
        <Flex alignItems="center">
        <div className="pr-3">
            <Avatar className={`ant-avatar-success`} icon={<UserOutlined />} />
          </div>
          <div className="mr-3">
            <span className="font-weight-bold text-dark">{appointment.fullName} </span>
            <span className="text-gray-light">has made an appointment for {appointment.tests.length} tests</span>
          </div>
          <small className="ml-auto">''</small>
        </Flex>
      </List.Item>
    )}
  />
  :
  <div className="empty-notification">
    <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
    <p className="mt-3">You have viewed all notifications</p>
  </div>;
}

export const NavNotification = ({ cart}) => {

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Appointments</h4>
      </div>
      <div className="nav-notification-body">
        {/* {getNotificationBody(cart)} */}
      </div>
    
    </div>
  );

  return (
    <Dropdown 
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        {/* { cart.length > 0 && (<Menu.Item key="notification">
          <Badge count={cart.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>)} */}
      </Menu>
    </Dropdown>
  )
}

// const mapStateToProps= state => ({
//   cart: state.appointment.cart
// })

// export default connect(mapStateToProps)(NavNotification);
export default NavNotification;