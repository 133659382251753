import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Role from "./Role";
import AgencyTests from "./AgencyTests"
import User from "./User";
import Coupon from "./Coupon";
import Section from "./Section";
import Machine from "./Machine";
import Patient from "./Patient";
import Equipment from "./Equipment";
import CheckList from "./CheckList";
import Maintenance from "./Maintenance";
import Department from "./Department";
import Appointment from "./Appointment";
import BranchLookUp from "./BranchLookUp";
import TestDirectory from "./TestDirectory";
import TestRegistration from "./TestRegistration";
import ClientCompany from "./ClientCompany";
import BranchTestCapacity from "./BranchTestCapacity";
import ClientCompanyTestDirectory from "./ClientCompanyTestDirectory";
import NewOrder from "./NewOrder";
import OrderMadeByGuestUsers from "./OrderMadeByGuestUser";
import InPersonDelivery from "./InPersonDelivery"
import Financials from "./Financials";
import Notyetreleased from "./Notyetreleased";
import OMBGUSampleNotRecievedByICL from "./OMBGUSampleNotRecievedByIcl";
import Released from "./Released";
import PatientResult from "./PatientResult";
import Discrepancy from "./Discrepancy";
import DiscrepancyApproved from "./DiscrepanciesStatus/DiscrepancyApproved";
import DiscrepancyDeclined from "./DiscrepanciesStatus/DiscrepancyDeclined";
import DiscrepancyPending from "./DiscrepanciesStatus/DiscrepancyPending";
import AllOrders from "./OrderStatus/AllOrders";
import ApprovedOrders from "./OrderStatus/ApprovedOrders";
import DeclinedOrders from "./OrderStatus/DeclinedOrders";
import PendingOrders from "./OrderStatus/PendingOrders";
import TopAgencies from "./TopAgencies";
import TopTests from "./TopTests";
import TopPanels from "./TopPanels";
import TopBranchesForWalkinCustomer from "./TopBranchesForWalkinCustomers";
import PanicandAbnormalTestResults from "./PanicandAbnormalTestResults";
import PatientTestResultHistory from "./PatientTestResultHistory";
import PatientSearch from "./PatientSearch";
export default function* rootSaga(getState) {
  yield all([
    Auth(),
    User(),
    Role(),
    AgencyTests(),
    Coupon(),
    Section(),
    Machine(),
    Patient(),
    Equipment(),
    CheckList(),
    Maintenance(),
    Department(),
    Appointment(),
    BranchLookUp(),
    TestDirectory(),
    TestRegistration(),
    ClientCompany(),
    BranchTestCapacity(),
    ClientCompanyTestDirectory(),
    NewOrder(),
    OrderMadeByGuestUsers(),
    InPersonDelivery(),
    Financials(),
    Notyetreleased(),
    OMBGUSampleNotRecievedByICL(),
    Released(),
    PatientResult(),
    Discrepancy(),
    DiscrepancyApproved(),
    DiscrepancyPending(),
    DiscrepancyDeclined(),
    AllOrders(),
    DeclinedOrders(),
    PendingOrders(),
    ApprovedOrders(),
    TopAgencies(),
    TopTests(),
    TopPanels(),
    TopBranchesForWalkinCustomer(),
    PanicandAbnormalTestResults(),
    PatientTestResultHistory(),
    PatientSearch(),
  ]);
}
