import fetch from "auth/FetchInterceptor";

const DiscrepancyDeclined = {};

DiscrepancyDeclined.get = function (payload) {
  return fetch({
    url:
      "/reports/discrepancy-declined?" +
      "startdate=" +
      payload.startDate +
      "&enddate=" +
      payload.endDate +
      "&agency=" +
      payload.agency +
      "&department=" +
      payload.department,
    method: "get",
  });
};

DiscrepancyDeclined.update = function (id, data) {
  return fetch({
    url: `/poly-tech-patient-pid/${id}`,
    method: "patch",
    data,
  });
};

DiscrepancyDeclined.Discrepancyobx = function (id) {
  return fetch({
    url: `/reports/discrepancy_obx/${id}`,
    method: "get",
  });
};

export default DiscrepancyDeclined;
