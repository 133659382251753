export const SHOW_ORDER_STATUS_EDIT_FORM = "SHOW_ORDER_STATUS_EDIT_FORM";

export const FETCH_ALL_ORDER_REQUEST = "FETCH_ALL_ORDER_REQUEST";
export const FETCH_ALL_ORDER_SUCCESS = "FETCH_ALL_ORDER_SUCCESS";
export const FETCH_ALL_ORDER_FAILURE = "FETCH_ALL_ORDER_FAILURE";

export const FETCH_ORDER_OBX_REQUEST = "FETCH_ORDER_OBX_REQUEST";
export const FETCH_ORDER_OBX_SUCCESS = "FETCH_ORDER_OBX_SUCCESS";
export const FETCH_ORDER_OBX_FAILURE = "FETCH_ORDER_OBX_FAILURE";

export const UPDATE_ALL_ORDER_REQUEST = "UPDATE_ALL_ORDER_REQUEST";
export const UPDATE_ALL_ORDER_SUCCESS = "UPDATE_ALL_ORDER_SUCCESS";
export const UPDATE_ALL_ORDER_FAILURE = "UPDATE_ALL_ORDER_FAILURE";
