import {
  FETCH_TEST_DIRECTORIES_SUCCESS,
  FETCH_TEST_DIRECTORIES_REQUEST,
  FETCH_TEST_DIRECTORIES_FAILURE,
  UPDATE_TEST_DIRECTORY_REQUEST,
  UPDATE_TEST_DIRECTORY_SUCCESS,
  UPDATE_TEST_DIRECTORY_FAILURE,
  SHOW_TEST_DIRECTORY_EDIT_FORM,
  DELETE_TEST_DIRECTORY_REQUEST,
  DELETE_TEST_DIRECTORY_SUCCESS,
  DELETE_TEST_DIRECTORY_FAILURE,
  SET_TEST_DIRECTORY_VALIDATION_ERRORS,
  SHOW_TEST_DIRECTORY_NEW_FORM,
  CREATE_TEST_DIRECTORY_REQUEST,
  CREATE_TEST_DIRECTORY_SUCCESS,
  CREATE_TEST_DIRECTORY_FAILURE,
} from "constants/TestDirectory";

import {
  addTestDirectory,
  formtValidationErrors,
  removeTestDirectory,
  updateTestDirectory,
} from "utils/TestDirectory";

const initState = {
  loading: false,
  updating: false,
  creating: false,
  list: [],
  errorMessage: "",
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const testDirectory = (state = initState, action) => {
  switch (action.type) {
    case FETCH_TEST_DIRECTORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TEST_DIRECTORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_TEST_DIRECTORIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_TEST_DIRECTORY_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_TEST_DIRECTORY_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_TEST_DIRECTORY_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_TEST_DIRECTORY_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_TEST_DIRECTORY_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateTestDirectory(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_TEST_DIRECTORY_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_TEST_DIRECTORY_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_TEST_DIRECTORY_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeTestDirectory(state, action),
      };
    case DELETE_TEST_DIRECTORY_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_TEST_DIRECTORY_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_TEST_DIRECTORY_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addTestDirectory(state, action),
      };
    case CREATE_TEST_DIRECTORY_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default testDirectory;
