import _ from "lodash";

export const addEquipment = (state, action) => {
  let list = state.list;
  const savedEquipment = action.payload;

  return [savedEquipment, ...list];
};

export const updateEquipment = (state, action) => {
  let list = state.list;
  const updatedEquipment = action.payload;

  const index = _.findIndex(list, ["id", updatedEquipment.id]);
  list[index] = updatedEquipment;

  return [...list];
};

export const removeEquipment = (state, action) => {
  let list = state.list;
  const deletedEquipmentId = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedEquipmentId;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};
