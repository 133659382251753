import _ from "lodash";

export const addSection = (state, action) => {
  let list = state.list;
  const savedSection = action.payload;

  return [savedSection, ...list];
};

export const updateSection = (state, action) => {
  let list = state.list;
  const updatedSection = action.payload;

  const index = _.findIndex(list, ["id", updatedSection.id]);
  list[index] = updatedSection;

  return [...list];
};

export const removeSection = (state, action) => {
  let list = state.list;
  const deletedSectionId = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedSectionId;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};
