import fetch from "auth/FetchInterceptor";

const MaintenanceService = {};

MaintenanceService.index = function () {
  return fetch({
    url: "/maintenances",
    method: "get",
  });
};

MaintenanceService.create = function (data) {
  return fetch({
    url: "/maintenances",
    method: "post",
    data,
  });
};

MaintenanceService.delete = function (id) {
  return fetch({
    url: `/maintenances/${id}`,
    method: "delete",
  });
};

export default MaintenanceService;