import _ from "lodash";

export const addDiscrepancy = (state, action) => {
  let list = state.list;
  const savedDiscrepancy = action.payload;

  return [savedDiscrepancy, ...list];
};

export const updateDiscrepancy = (state, action) => {
  let list = state.list;
  const updatedDiscrepancy = action.payload;

  const index = _.findIndex(list, ["id", updatedDiscrepancy.id]);
  list[index] = updatedDiscrepancy;

  return [...list];
};

export const removeDiscrepancy = (state, action) => {
  let list = state.list;
  const deletedDiscrepancy = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedDiscrepancy.id;
  });

  return [...filteredList];
};
