import {
  DashboardOutlined,
  HomeOutlined,
  OrderedListOutlined,
  BranchesOutlined,
  BankOutlined,
  ClusterOutlined,
  UnorderedListOutlined,
  UserOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  ADMIN_PREFIX_PATH,
  APP_PREFIX_PATH,
  QC_PREFIX_PATH,
  ROLE_GUEST,
  ROLE_QUALITY_CONTROL,
  ROLE_SUPER_ADMIN,
} from "configs/AppConfig";
import { CURRENT_USER } from "redux/constants/Auth";
import {
  VIEW_AGENCIES_TESTS,
  VIEW_ALL_DISCREPANCIES,
  VIEW_ALL_ORDERS,
  VIEW_ALL_RELEASED_RESULTS,
  VIEW_APPROVED_DESCREPANCIES,
  VIEW_BRANCHLOOKUPS,
  VIEW_BRANCH_TEST_CAPACITIES,
  VIEW_CLIENTCOMPANIES,
  VIEW_CLIENTCOMPANYTESTDIRECTORIES,
  VIEW_COUPONS,
  VIEW_DASHBOARD,
  VIEW_DECLINED_DESCREPANCIES,
  VIEW_DEPARTMENTS,
  VIEW_PANEL_OBR,
  VIEW_FINANCIAL,
  VIEW_INPERSON_RESULTS,
  VIEW_NEWORDERS,
  VIEW_NOT_RELEASED_ORDERS,
  VIEW_PANIC_AND_ABNORMAL_TESTS,
  VIEW_PATIENT_RESULT_HISTORY,
  VIEW_PENDING_DISCREPANCIES,
  VIEW_RELEASED_PANEL_FOR_ICL,
  VIEW_REPORTS,
  VIEW_RESULTS_FOR_GUEST_USERS,
  VIEW_ROLES,
  VIEW_SAMPLE_NOT_RECIEVED_ORDERS,
  VIEW_TESTDIRECTORIES,
  VIEW_TOP_AGENCIES,
  VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS,
  VIEW_TOP_TESTS,
  VIEW_USERS,
  APPROVE_WEB_ORDER,
  VIEW_APPROVED_ORDERS,
  VIEW_PENDING_ORDERS,
  VIEW_DELCINED_ORDERS,
  VIEW_AGENCY_TEST_ASSOCIATION_FORM
} from "./UserPermissions";
import { DoesThisUserHasAPermissionToThisComponent } from "utils/PermissionChecking";
const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const qualityControlNavTree = [
  {
    key: "sections",
    path: `${QC_PREFIX_PATH}/sections`,
    title: "Section",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "machines",
    path: `${QC_PREFIX_PATH}/machines`,
    title: "Machine",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "equipment",
    path: `${QC_PREFIX_PATH}/equipment`,
    title: "Equipment",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "check-lists",
    path: `${QC_PREFIX_PATH}/check-lists`,
    title: "CheckList",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "maintenances",
    path: `${QC_PREFIX_PATH}/maintenances`,
    title: "Maintenance",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
const role = currentUser?.role;
let navigationConfig = null;

  if ((role != ROLE_QUALITY_CONTROL && role != ROLE_GUEST) || role == ROLE_SUPER_ADMIN) {
  navigationConfig = [
    {
      key: "user-dashboard",
      path: `${ADMIN_PREFIX_PATH}`,
      title: "Home",
      icon: HomeOutlined,
      submenu: [],
    },
    {
      key: "dashboards",
      path: `${APP_PREFIX_PATH}/dashboards`,
      title: "Tasks",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [
        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ROLES)
          ? ([{
              key: "role",
              path: `${ADMIN_PREFIX_PATH}/roles`,
              title: "Roles",
              icon: UserAddOutlined,
              submenu: [],
            }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_USERS)
        ? ([{
          key: "user",
          path: `${ADMIN_PREFIX_PATH}/users`,
          title: "Users",
          icon: UserOutlined,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_AGENCY_TEST_ASSOCIATION_FORM)
        ? ([{
          key: "agencytests",
          path: `${ADMIN_PREFIX_PATH}/agency-tests`,
          title: "Agency Tests",
          icon: UserOutlined,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_COUPONS)
        ? ([{
          key: "coupon",
          path: `${ADMIN_PREFIX_PATH}/coupons`,
          title: "Coupons",
          icon: UserOutlined,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_DEPARTMENTS)
        ? ([{
          key: "department",
          path: `${ADMIN_PREFIX_PATH}/departments`,
          title: "Departments",
          icon: ClusterOutlined,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_NEWORDERS)
        ? ([{
          key: "neworder",
          path: `${ADMIN_PREFIX_PATH}/neworder`,
          title: "New order",
          icon: ClusterOutlined,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_RELEASED_RESULTS)
         || DoesThisUserHasAPermissionToThisComponent(VIEW_RESULTS_FOR_GUEST_USERS)
         || DoesThisUserHasAPermissionToThisComponent(VIEW_PANIC_AND_ABNORMAL_TESTS)
         || DoesThisUserHasAPermissionToThisComponent(VIEW_INPERSON_RESULTS)
         || DoesThisUserHasAPermissionToThisComponent(VIEW_PATIENT_RESULT_HISTORY)

        ? ([{
          key: "results",
          path: `${ADMIN_PREFIX_PATH}/client`,
          title: "Released Results",
          icon: BankOutlined,
          breadcrumb: true,
          submenu: [
            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_RELEASED_RESULTS)
            ? ([{
              key: "patientresult",
              path: `${ADMIN_PREFIX_PATH}/patient-result`,
              title: "All Results",
              icon: UnorderedListOutlined,
              breadcrumb: true,
              submenu: [],
            }]) : []),

            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_RESULTS_FOR_GUEST_USERS)
            ? ([{
              key: "ordermadebyguestuser",
              path: `${ADMIN_PREFIX_PATH}/ordermadebyguestusers`,
              title: "For Guest Users",
              icon: UnorderedListOutlined,
              breadcrumb: true,
              submenu: [],
            }]) : []),

            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_PANIC_AND_ABNORMAL_TESTS)
            ? ([{
              key: "panicandabnormaltests",
              path: `${ADMIN_PREFIX_PATH}/panicandabnormaltests`,
              title: "Panic Results",
              icon: UnorderedListOutlined,
              submenu: [],
           }]) : []),

            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_INPERSON_RESULTS)
            ? ([{
              key: "inpersonresultdelivery",
              path: `${ADMIN_PREFIX_PATH}/in-person-result-delivery`,
              title: "In person (Confidential) Results",
              icon: UnorderedListOutlined,
              submenu: [],
            }]) : []),

            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_PATIENT_RESULT_HISTORY)
            ? ([{
              key: "patienttestresulthistory",
              path: `${ADMIN_PREFIX_PATH}/patienttestresulthistory`,
              title: "Patient Result History",
              icon: UnorderedListOutlined,
              submenu: [],
           }]) : []),
          ],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS)
         || DoesThisUserHasAPermissionToThisComponent(VIEW_NOT_RELEASED_ORDERS)
         || DoesThisUserHasAPermissionToThisComponent(VIEW_SAMPLE_NOT_RECIEVED_ORDERS)
         || DoesThisUserHasAPermissionToThisComponent(VIEW_RELEASED_PANEL_FOR_ICL)
        ? ([{
          key: "orders",
          path: `${ADMIN_PREFIX_PATH}/client`,
          title: "Orders",
          icon: BankOutlined,
          breadcrumb: true,
          submenu: [

            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_ORDERS)
            ? ([{
              key: "released",
              path: `${ADMIN_PREFIX_PATH}/all-orders`,
              title: "All Orders",
              icon: ClusterOutlined,
              breadcrumb: true,
              submenu: [],
            }]) : []),

            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_ORDERS)
            ? ([{
              key: "approved-results",
              path: `${ADMIN_PREFIX_PATH}/approved-orders`,
              title: "Approved Orders",
              icon: ClusterOutlined,
              breadcrumb: true,
              submenu: [],
            }]) : []),

            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_ORDERS)
            ? ([{
              key: "pending-results",
              path: `${ADMIN_PREFIX_PATH}/pending-orders`,
              title: "Pending Orders",
              icon: ClusterOutlined,
              breadcrumb: true,
              submenu: [],
            }]) : []),

            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_DELCINED_ORDERS)
            ? ([{
              key: "declined-results",
              path: `${ADMIN_PREFIX_PATH}/declined-orders`,
              title: "Declined Orders",
              icon: ClusterOutlined,
              breadcrumb: true,
              submenu: [],
            }]) : []),

            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_NOT_RELEASED_ORDERS)
            ? ([{
              key: "notyetreleased",
              path: `${ADMIN_PREFIX_PATH}/not-yet-released`,
              title: "Not yet Released",
              icon: ClusterOutlined,
              breadcrumb: true,
              submenu: [],
            }]) : []),

            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_SAMPLE_NOT_RECIEVED_ORDERS)
            ? ([{
              key: "notyetrecieved",
              path: `${ADMIN_PREFIX_PATH}/not-yet-recieved`,
              title: "Not Yet Recieved",
              icon: ClusterOutlined,
              breadcrumb: true,
              submenu: [],
            }]) : []),

            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_RELEASED_PANEL_FOR_ICL)
            ? ([{
              key: "released for icl",
              path: `${ADMIN_PREFIX_PATH}/released-panels`,
              title: "Released Panels For ICL",
              icon: ClusterOutlined,
              submenu: [],
            }]) : []),
          ],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_DISCREPANCIES)
        || DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_DESCREPANCIES)
        || DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_DISCREPANCIES)
        || DoesThisUserHasAPermissionToThisComponent(VIEW_DECLINED_DESCREPANCIES)
       ? ([{
          key: "discrepancy",
          path: `${ADMIN_PREFIX_PATH}/discrepancy`,
          title: "Discrepancy",
          icon: BankOutlined,
          breadcrumb: true,
          submenu: [
            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_ALL_DISCREPANCIES)
            ? ([{
              key: "discrepancyall",
              path: `${ADMIN_PREFIX_PATH}/discrepancy/all`,
              title: "Discrepancy-All",
              icon: UnorderedListOutlined,
              breadcrumb: true,
              submenu: [],
            }]) : []),
            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_PENDING_DISCREPANCIES)
            ? ([{
              key: "discrepancy-pending",
              path: `${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-pending`,
              title: "Pending",
              icon: UnorderedListOutlined,
              breadcrumb: true,
              submenu: [],
            }]) : []),
            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_APPROVED_DESCREPANCIES)
            ? ([{
              key: "discrepancy-approved",
              path: `${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-approved`,
              title: "Approved",
              icon: UnorderedListOutlined,
              breadcrumb: true,
              submenu: [],
            }]) : []),
            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_DECLINED_DESCREPANCIES)
            ? ([{
              key: "discrepancy-declined",
              path: `${ADMIN_PREFIX_PATH}/discrepancy/discrepancy-declined`,
              title: "Declined",
              icon: UnorderedListOutlined,
              breadcrumb: true,
              submenu: [],
            }]) : []),
          ],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_BRANCH_FOR_WALKIN_CUSTOMERS)
        ? ([{
          key: "topbranchesforwalkincustomer",
          path: `${ADMIN_PREFIX_PATH}/top-branchs-for-walkin-customers`,
          title: "Top Branches for Walk-in-Customers",
          icon: BranchesOutlined,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_AGENCIES)
        ? ([{
          key: "topagencies",
          path: `${ADMIN_PREFIX_PATH}/top-agencies`,
          title: "Top Agencies",
          icon: ClusterOutlined,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TOP_TESTS)
        ? ([{
          key: "toptests",
          path: `${ADMIN_PREFIX_PATH}/top-tests`,
          title: "Top Tests",
          icon: ClusterOutlined,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_PANEL_OBR)
        ? ([{
          key: "toptests",
          path: `${ADMIN_PREFIX_PATH}/top-panels`,
          title: "Top Panels",
          icon: ClusterOutlined,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_DASHBOARD)
        ? ([{
          key: "dashboard2",
          path: `${ADMIN_PREFIX_PATH}/dashboard2`,
          title: "Dashboard",
          icon: ClusterOutlined,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_REPORTS)
        ? ([{
          key: "reports",
          path: `${ADMIN_PREFIX_PATH}/reports`,
          title: "Reports",
          icon: ClusterOutlined,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_FINANCIAL)
        ? ([{
          key: "financial",
          path: `${ADMIN_PREFIX_PATH}/financial`,
          title: "Financial",
          icon: ClusterOutlined,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_TESTDIRECTORIES)
        ? ([{
          key: "test-directories",
          path: `${ADMIN_PREFIX_PATH}/test-directories`,
          title: "Test Directory",
          icon: OrderedListOutlined,
          breadcrumb: true,
          submenu: [],
        }]) : []),

       ...(DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCHLOOKUPS)
        ? ([{
          key: "branch-look-ups",
          path: `${ADMIN_PREFIX_PATH}/branch-look-ups`,
          title: "Branch Lookup",
          icon: BranchesOutlined,
          breadcrumb: true,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_BRANCH_TEST_CAPACITIES)
        ? ([{
          key: "branch-test-capacities",
          path: `${ADMIN_PREFIX_PATH}/branch-test-capacities`,
          title: "Branch Test Capacity",
          icon: BranchesOutlined,
          breadcrumb: true,
          submenu: [],
        }]) : []),

        ...(DoesThisUserHasAPermissionToThisComponent(VIEW_CLIENTCOMPANIES)
        || DoesThisUserHasAPermissionToThisComponent(VIEW_CLIENTCOMPANYTESTDIRECTORIES)
       ? ([{
          key: "client",
          path: `${ADMIN_PREFIX_PATH}/client`,
          title: "Clients",
          icon: BankOutlined,
          breadcrumb: true,
          submenu: [
            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_CLIENTCOMPANIES)
            ? ([{
              key: "client-list",
              path: `${ADMIN_PREFIX_PATH}/client-companies`,
              title: "Client List",
              icon: "",
              breadcrumb: true,
              submenu: [],
            }]) : []),
            ...(DoesThisUserHasAPermissionToThisComponent(VIEW_CLIENTCOMPANYTESTDIRECTORIES)
            ? ([{
              key: "client-test-directories",
              path: `${ADMIN_PREFIX_PATH}/client-company-test-directories`,
              title: "Test Directories",
              icon: "",
              breadcrumb: true,
              submenu: [],
            }]) : []),
          ],
        }]) : []),
      ],
    },
  ];
} else if (role === "qc-admin") {
  navigationConfig = [...qualityControlNavTree];
} else navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
