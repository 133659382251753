import fetch from "auth/FetchInterceptor";

const DiscrepancyService = {};

DiscrepancyService.index = function (payload) {
  return fetch({
    url:
      "/reports/discrepancy-all?" +
      "startdate=" +
      payload.startDate +
      "&enddate=" +
      payload.endDate +
      "&agency=" +
      payload.agency +
      "&department=" +
      payload.department,
    method: "get",
  });
};

DiscrepancyService.update = function (id, data) {
  return fetch({
    url: `/poly-tech-patient-pid/${id}`,
    method: "patch",
    data,
  });
};

DiscrepancyService.Discrepancyobx = function (id) {
  return fetch({
    url: `/reports/discrepancy_obx/${id}`,
    method: "get",
  });
};

export default DiscrepancyService;
