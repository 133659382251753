import {
  FETCH_PATIENTRESULTS_FAILURE,
  FETCH_PATIENTRESULTS_REQUEST,
  FETCH_PATIENTRESULTS_SUCCESS,
} from "constants/PatientResult";

export const fetchPatientResultsRequest = (startDate, endDate, agency, department) => ({
  type: FETCH_PATIENTRESULTS_REQUEST,
  payload : {
      startDate, 
      endDate, 
      agency,
      department
  }
});

export const fetchPatientResultsSuccess = (patientresults) => ({
  type: FETCH_PATIENTRESULTS_SUCCESS,
  payload: patientresults,
});

export const fetchPatientResultsFailure = (error) => ({
  type: FETCH_PATIENTRESULTS_FAILURE,
  payload: error,
});
