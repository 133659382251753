/* DATE FORMAT CONSTANTS THAT WE ARE GOING TO USE THROUGH OUT THE APPLICATION */
/*1 */ export const DATE_FORMAT_DATABASE = "YYYYMMDDTHHMMSS"; // 20220615T113830
/*2 */ export const DATE_IN_NUMBER_FORMAT = "L"; // 06/15/2022
/*3 */ export const DAY_MONTH_YEAR_HOUR_MINUTE_WHILE_BOTH_DAY_AND_MONTH_ARE_STRING_FORMAT = "LLLL"; // Wednesday, July 4, 2001 12:08 PM

/*4 */ export const MONTH_AND_DAY_WHILE_MONTH_IN_STRING_FORMAT= "MMMM DD"; // July 04
/*5 */ export const MONTH_DAY_YEAR_WHILE_MONTH_IN_STRING_FORMAT = "LL"; // July 22, 2022
/*6 */ export const MONTH_AND_DAY_IN_NUMBER_FORMAT = "MM-DD"; // 07-04

/*7 */ export const HOURE_AND_MINUTE_FORMAT = "LT"; // 11:38 AM
/*8 */ export const HOURE__MINUTE_AND_SECOND_FORMAT= "LTS"; // 11:38:20 AM
/*9 */ export const DATE_AND_TIME_FOR_TABLE_FORMAT= "MMM DD, hh:mm A"; // Oct 3rd, 10:08 am


/*
but on the above senario the possible set of date parsing (conversion) are listed below.
1. DATE_FORMAT_DATABASE can be parsed to all FORMATS (1,2,3,4,5,6,7,8).
2. DATE_IN_NUMBER_FORMAT can be parsed to (3,4,5,6)
3. DATE_MONTH_YEAR_HOUR_MINUTE_WHILE_BOTH_DATE_AND_MONTH_ARE_STRING_FORMAT can be parsed to (2,4,5,6,7,8)
4. MONTH_AND_DATE_WHILE_MONTH_IN_STRING_FORMAT can be parsed to (6)
5. MONTH_DATE_YEAR_WHILE_MONTH_IN_STRING_FORMAT can be parsed to (2,3,4,6)
*/
