import {
  FETCH_ORDER_MADE_BY_GUEST_USERS_REQUEST,
  FETCH_ORDER_MADE_BY_GUEST_USERS_SUCCESS,
  FETCH_ORDER_MADE_BY_GUEST_USERS_FAILURE,
  SET_ORDER_MADE_BY_GUEST_USRS_VALIDATION_ERROR,
  SHOW_ORDER_MADE_BY_GUEST_USERS_EDIT_FORM,
  DELETE_ORDER_MADE_BY_GUEST_USERS_REQUEST,
  DELETE_ORDER_MADE_BY_GUEST_USERS_SUCCESS,
  DELETE_ORDER_MADE_BY_GUEST_USERS_FAILURE,
  UPDATE_ORDER_MADE_BY_GUEST_USERS_REQUEST,
  UPDATE_ORDER_MADE_BY_GUEST_USERS_SUCCESS,
  UPDATE_ORDER_MADE_BY_GUEST_USERS_FAILURE,
} from "../../constants/OrderMadeByGuestUsers";

import {
  updateOrderMadeByGuestUsers,
  formValidationErrors,
  removeOrderMadeByGuestUsers,
} from "../../utils/OrderMadeByGuestUsers";

import { changeDateToHumanReadableFormat } from "../../utils/DateTimeUtil";

const initState = {
  loading: false,
  updating: false,
  list: [],
  errorMessage: "",
  validationErrors: [],
  showEditForm: false,
  deleting: false,
};

const orderMadeByGuestUsersState = (state = initState, action) => {
  switch (action.type) {
    case FETCH_ORDER_MADE_BY_GUEST_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };

    case FETCH_ORDER_MADE_BY_GUEST_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: changeDateToHumanReadableFormat(
          action.payload
        ),
      };

    case FETCH_ORDER_MADE_BY_GUEST_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_ORDER_MADE_BY_GUEST_USRS_VALIDATION_ERROR:
      return {
        ...state,
        validationErrors: formValidationErrors(action.payload),
      };

    case SHOW_ORDER_MADE_BY_GUEST_USERS_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };

    case DELETE_ORDER_MADE_BY_GUEST_USERS_REQUEST:
      return {
        ...state,
        deleting: true,
      };

    case DELETE_ORDER_MADE_BY_GUEST_USERS_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeOrderMadeByGuestUsers(action.payload),
      };

    case DELETE_ORDER_MADE_BY_GUEST_USERS_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case UPDATE_ORDER_MADE_BY_GUEST_USERS_REQUEST:
      return {
        ...state,
        updating: true,
      };

    case UPDATE_ORDER_MADE_BY_GUEST_USERS_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateOrderMadeByGuestUsers(action.payload),
      };

    case UPDATE_ORDER_MADE_BY_GUEST_USERS_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default orderMadeByGuestUsersState;
