import {
  DELETE_TEST_DIRECTORY_FAILURE,
  DELETE_TEST_DIRECTORY_REQUEST,
  DELETE_TEST_DIRECTORY_SUCCESS,
  FETCH_TEST_DIRECTORIES_FAILURE,
  FETCH_TEST_DIRECTORIES_REQUEST,
  FETCH_TEST_DIRECTORIES_SUCCESS,
  CREATE_TEST_DIRECTORY_FAILURE,
  CREATE_TEST_DIRECTORY_REQUEST,
  CREATE_TEST_DIRECTORY_SUCCESS,
  SET_TEST_DIRECTORY_VALIDATION_ERRORS,
  SHOW_TEST_DIRECTORY_EDIT_FORM,
  SHOW_TEST_DIRECTORY_NEW_FORM,
  UPDATE_TEST_DIRECTORY_FAILURE,
  UPDATE_TEST_DIRECTORY_REQUEST,
  UPDATE_TEST_DIRECTORY_SUCCESS,
} from "constants/TestDirectory";

export const fetchTestDirectoriesRequest = () => ({
  type: FETCH_TEST_DIRECTORIES_REQUEST,
});

export const fetchTestDirectoriesSuccess = (testDirectories) => ({
  type: FETCH_TEST_DIRECTORIES_SUCCESS,
  payload: testDirectories,
});

export const fetchTestDirectoriesFailure = (error) => ({
  type: FETCH_TEST_DIRECTORIES_FAILURE,
  payload: error,
});

export const setTestDirectoryValidationErrors = (validationErrors) => ({
  type: SET_TEST_DIRECTORY_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleTestDirectoryEditForm = () => ({
  type: SHOW_TEST_DIRECTORY_EDIT_FORM,
});

export const toggleTestDirectoryNewForm = () => ({
  type: SHOW_TEST_DIRECTORY_NEW_FORM,
});

export const updateTestDirectoyRequest = (testDirectory) => ({
  type: UPDATE_TEST_DIRECTORY_REQUEST,
  payload: testDirectory,
});

export const updateTestDirectoySuccess = (testDirectory) => ({
  type: UPDATE_TEST_DIRECTORY_SUCCESS,
  payload: testDirectory,
});

export const updateTestDirectoyFailure = (error) => ({
  type: UPDATE_TEST_DIRECTORY_FAILURE,
  payload: error,
});

export const deleteTestDirectoyRequest = (id) => ({
  type: DELETE_TEST_DIRECTORY_REQUEST,
  payload: id,
});

export const deleteTestDirectoySuccess = (testDirectory) => ({
  type: DELETE_TEST_DIRECTORY_SUCCESS,
  payload: testDirectory,
});

export const deleteTestDirectoyFailure = (error) => ({
  type: DELETE_TEST_DIRECTORY_FAILURE,
  payload: error,
});

export const createTestDirectoyRequest = (testDirectory) => ({
  type: CREATE_TEST_DIRECTORY_REQUEST,
  payload: testDirectory,
});

export const createTestDirectoySuccess = (testDirectory) => ({
  type: CREATE_TEST_DIRECTORY_SUCCESS,
  payload: testDirectory,
});

export const createTestDirectoyFailure = (error) => ({
  type: CREATE_TEST_DIRECTORY_FAILURE,
  payload: error,
});
