import {
  ADD_TEST_DIRECTORY,
  FETCH_APPOINTMENT_METADATA_FAILURE,
  FETCH_APPOINTMENT_METADATA_REQUEST,
  FETCH_APPOINTMENT_METADATA_SUCCESS,
  ADD_TEST_CATEGORY,
  SET_NUMBER_OF_PATIENT,
  ADD_PATIENT,
  REMOVE_PATIENT,
  SET_APPOINTMENT_DATE,
  SET_APPOINTMENT_TIME,
  SET_BRANCH_ID,
  SET_APPOINTMENT_DAY_NAME,
  CLEAR_APPOINTMENT_TIME,
  SET_PAYMENT_METHOD,
} from "constants/Appointment";
import { addPatient, addTestDirectory, removePatient } from "utils/Appointment";

const initState = {
  loadingAppointmentMetadata: false,
  appointmentMetadata: null,
  appointmentMetadataErrorMessage: "",
  selectedTestDirectories: [],
  selectedTestCategory: "",
  numberOfPatient: 0,
  appointmentDate: null,
  appointmentDayName: null,
  appointmentTime: null,
  branchID: null,
  paymentMethod: null,
  patients: [],
};

const appointment = (state = initState, action) => {
  switch (action.type) {
    case FETCH_APPOINTMENT_METADATA_REQUEST:
      return {
        ...state,
        loadingAppointmentMetadata: true,
      };
    case FETCH_APPOINTMENT_METADATA_SUCCESS:
      return {
        ...state,
        loadingAppointmentMetadata: false,
        appointmentMetadata: action.payload,
      };
    case FETCH_APPOINTMENT_METADATA_FAILURE:
      return {
        ...state,
        loadingAppointmentMetadata: false,
        appointmentMetadataErrorMessage: action.payload,
      };
    case ADD_TEST_CATEGORY:
      return {
        ...state,
        selectedTestCategory: action.payload,
        selectedTestDirectories: [],
      };
    case ADD_TEST_DIRECTORY:
      return {
        ...state,
        selectedTestDirectories: addTestDirectory(state, action),
      };
    case SET_NUMBER_OF_PATIENT:
      return {
        ...state,
        numberOfPatient: action.payload,
      };
    case SET_APPOINTMENT_DATE:
      return {
        ...state,
        appointmentDate: action.payload,
      };
    case SET_APPOINTMENT_DAY_NAME:
      return {
        ...state,
        appointmentDayName: action.payload,
      };
    case SET_APPOINTMENT_TIME:
      return {
        ...state,
        appointmentTime: action.payload,
      };
    case CLEAR_APPOINTMENT_TIME:
      return {
        ...state,
        appointmentTime: null,
        paymentMethod: null,
      };
    case SET_BRANCH_ID:
      return {
        ...state,
        branchID: action.payload,
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case ADD_PATIENT:
      return {
        ...state,
        patients: addPatient(state, action),
      };
    case REMOVE_PATIENT:
      return {
        ...state,
        patients: removePatient(state, action),
      };
    default:
      return state;
  }
};

export default appointment;
