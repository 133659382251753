import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import {
  FETCH_PATIENTTESTRESULTHISTORY_REQUEST,
  FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
} from "constants/PatientTestResultHistory";
import {
  fetchPatientTestResultHistoryFailure,
  fetchPatientTestResultHistorySuccess,
  // fetchPatientTestResultHistorySuccess,
  // fetchPatientTestResultHistoryFailure,
} from "redux/actions";

import PatientTestResultHistoryService from "services/PatientTestResultHistoryService";

import NewOrderService from "services/NewOrder";

export function* onFetchPatientTestResultHistory() {
  yield takeLatest(FETCH_PATIENTTESTRESULTHISTORY_REQUEST, function* () {
    try {
      const result = yield call(PatientTestResultHistoryService.index);
      if (result.status & (result.data.length < 1)) {
        message.warning("No Prior History Found For This Patient");
        yield put(fetchPatientTestResultHistoryFailure(result.message));
      }
      if (result.status & (result.data.length > 0)) {
        yield put(fetchPatientTestResultHistorySuccess(result.data));
      } else {
        yield put(fetchPatientTestResultHistoryFailure(result.message));
      }
    } catch (error) {
      yield put(fetchPatientTestResultHistoryFailure(error.message));
    }
  });
}

export function* onFetchExistingPatients() {
  yield takeLatest(
    FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
    function* ({ payload }) {
      try {
        const result = yield call(NewOrderService.searchpatient, payload);
        if (result.status & (result.data.length < 1)) {
          message.warning("Not found");
          yield put(fetchPatientTestResultHistoryFailure(result.message));
        }
        if (result.status & (result.data.length > 0)) {
          yield put(fetchPatientTestResultHistorySuccess(result.data));
        } else {
          yield put(fetchPatientTestResultHistoryFailure(result.message));
        }
      } catch (error) {
        yield put(fetchPatientTestResultHistoryFailure(error.message));
      }
    }
  );
}

// export function* onFetchExistingPatients() {
//   yield takeLatest(FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST, function* ({payload}) {
//     try {
//       const result = yield call(NewOrderService.searchpatient,payload);
//       if(result.status & result.data.length < 1 ){
//         message.warning("Not found");
//         yield put(fetchNewOrderPatientsFailure(result.message));
//       }
//       if (result.status  & result.data.length > 0) {
//         yield put(fetchNewOrderPatientsSuccess(result.data));
//     } else {
//         yield put(fetchNewOrderPatientsFailure(result.message));
//       }
//     } catch (error) {

//       yield put(fetchNewOrderPatientsFailure(error.message));
//     }
//   });
// }

export default function* rootSaga() {
  yield all([
    fork(onFetchPatientTestResultHistory),
    fork(onFetchExistingPatients),
  ]);
}
