import {
  FETCH_FINANCIALS_FAILURE,
  FETCH_FINANCIALS_REQUEST,
  FETCH_FINANCIALS_SUCCESS,  
} from "constants/Financials";

export const fetchFinancialsRequest = (startDate, endDate, agency, department) => ({
  type: FETCH_FINANCIALS_REQUEST,
  payload : {
     startDate, 
     endDate, 
     agency, 
     department
  }
});

export const fetchFinancialsSuccess = (financials) => ({
  type: FETCH_FINANCIALS_SUCCESS,
  payload: financials,
});

export const fetchFinancialsFailure = (error) => ({
  type: FETCH_FINANCIALS_FAILURE,
  payload: error,
});


