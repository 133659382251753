export const SHOW_DISCREPANCY_NEW_FORM = "SHOW_DISCREPANCY_PENDING_NEW_FORM";
export const SHOW_DISCREPANCY_EDIT_FORM = "SHOW_DISCREPANCY_PENDING_EDIT_FORM";
export const SET_DISCREPANCY_VALIDATION_ERRORS = "SET_DISCREPANCY_PENDING_VALIDATION_ERRORS";

export const FETCH_DISCREPANCY_REQUEST = "FETCH_DISCREPANCY_PENDING_REQUEST";
export const FETCH_DISCREPANCY_SUCCESS = "FETCH_DISCREPANCY_PENDING_SUCCESS";
export const FETCH_DISCREPANCY_FAILURE = "FETCH_DISCREPANCY_PENDING_FAILURE";

export const FETCH_DISCREPANCYOBX_REQUEST = "FETCH_DISCREPANCYOBX_PENDING_REQUEST";
export const FETCH_DISCREPANCYOBX_SUCCESS = "FETCH_DISCREPANCYOBX_PENDING_SUCCESS";
export const FETCH_DISCREPANCYOBX_FAILURE = "FETCH_DISCREPANCYOBX_PENDING_FAILURE";

export const UPDATE_DISCREPANCY_REQUEST = "UPDATE_DISCREPANCY_PENDING_REQUEST";
export const UPDATE_DISCREPANCY_SUCCESS = "UPDATE_DISCREPANCY_PENDING_SUCCESS";
export const UPDATE_DISCREPANCY_FAILURE = "UPDATE_DISCREPANCY_PENDING_FAILURE";

export const DELETE_DISCREPANCY_REQUEST = "DELETE_DISCREPANCY_PENDING_REQUEST";
export const DELETE_DISCREPANCY_SUCCESS = "DELETE_DISCREPANCY_PENDING_SUCCESS";
export const DELETE_DISCREPANCY_FAILURE = "DELETE_DISCREPANCY_PENDING_FAILURE";

export const CREATE_DISCREPANCY_REQUEST = "CREATE_DISCREPANCY_PENDING_REQUEST";
export const CREATE_DISCREPANCY_SUCCESS = "CREATE_DISCREPANCY_PENDING_SUCCESS";
export const CREATE_DISCREPANCY_FAILURE = "CREATE_DISCREPANCY_PENDING_FAILURE";
