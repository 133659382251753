import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { FETCH_PATIENTRESULTS_REQUEST } from "constants/PatientResult";
import {
  fetchPatientResultsFailure,
  fetchPatientResultsSuccess,
} from "redux/actions";
import PatientResultService from "services/PatientResult";

export function* onFetchPatientResults() {
  yield takeLatest(FETCH_PATIENTRESULTS_REQUEST, function* (payload) {
    try {
      const result = yield call(PatientResultService.index, payload.payload);
      if (result.status) {
        yield put(fetchPatientResultsSuccess(result.data));
      } else {
        yield put(fetchPatientResultsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchPatientResultsFailure(error.message));
    }
    // console.log('aaaa');
  });
}

export default function* rootSaga() {
  yield all([fork(onFetchPatientResults)]);
}
