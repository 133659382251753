import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_EXISTING_PATIENT_REQUEST,
 } from "constants/PatientSearch";
import {

  fetchPatientSearchSuccess,
  fetchPatientSearchFailure,
 
} from "redux/actions";

import PatientSearchService from "services/PatientSearchService";

export function* onFetchExistingPatients() {
  yield takeLatest(FETCH_EXISTING_PATIENT_REQUEST, function* ({payload}) {
    try {
      const result = yield call(PatientSearchService.searchpatient,payload);
      if(result.status & result.data.length < 1 ){
        message.warning("Not found");
        yield put(fetchPatientSearchFailure(result.message));
      }
      if (result.status  & result.data.length > 0) {
        yield put(fetchPatientSearchSuccess(result.data));
    } else {
        yield put(fetchPatientSearchFailure(result.message));
      }
    } catch (error) {
   
      yield put(fetchPatientSearchFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchExistingPatients),
  ]);
}
