import{
    FETCH_ALL_ORDER_FAILURE,
    FETCH_ALL_ORDER_REQUEST,
    FETCH_ALL_ORDER_SUCCESS,
    FETCH_ORDER_OBX_REQUEST,
    FETCH_ORDER_OBX_SUCCESS,
    FETCH_ORDER_OBX_FAILURE,
    UPDATE_ALL_ORDER_REQUEST,
    UPDATE_ALL_ORDER_FAILURE,
    UPDATE_ALL_ORDER_SUCCESS,
    } from "constants/OrderStatus/all-orders"
import { changeFormatedDatetoHumanReadableFormat } from "utils/DateTimeUtil"
import { updateOrder } from "utils/Order"

const initState = {
     loading : false,
     loadingObx : false,
     list : [],
     obxList : [],
     updatedOrder : [],
     errorMessage : ""
}

const AllOrders = (state = initState, action)=>{
     switch(action.type)
     {
         case  FETCH_ALL_ORDER_REQUEST : 
         return { 
            ...state,
            loading : true,
         }
         
         case FETCH_ALL_ORDER_SUCCESS : 
         return {
             ...state,
             loading : false,
             list : changeFormatedDatetoHumanReadableFormat(action.payload)
         }
         
         case FETCH_ALL_ORDER_FAILURE : 
          return {
             ...state,
             loading : false,
             errorMessage : action.payload
          }
         
          case FETCH_ORDER_OBX_REQUEST : 
          return {
             ...state,
             loadingObx : true,
          }

          case FETCH_ORDER_OBX_SUCCESS : 
          return {
             ...state,
             loadingObx : false,
             obxList : action.payload
          }

          case FETCH_ORDER_OBX_FAILURE : 
          return {
             ...state,
             loadingObx : false,
             errorMessage : action.payload
          }

         case UPDATE_ALL_ORDER_REQUEST : 
         return { 
            ...state,
            loadingObx : true,
         }
         case UPDATE_ALL_ORDER_SUCCESS : 
         return { 
             ...state,
             loadingObx : false,
            //  list : updateOrder(state, action)
         }
       
         case UPDATE_ALL_ORDER_FAILURE : 
         return { 
            ...state,
            errorMessage : action.payload
         }

         default : 
         return { 
            state
         }
     }
}

export default AllOrders