import {
  FETCH_ORDER_MADE_BY_GUEST_USERS_REQUEST,
  FETCH_ORDER_MADE_BY_GUEST_USERS_SUCCESS,
  FETCH_ORDER_MADE_BY_GUEST_USERS_FAILURE,
  SET_ORDER_MADE_BY_GUEST_USRS_VALIDATION_ERROR,
  SHOW_ORDER_MADE_BY_GUEST_USERS_EDIT_FORM,
  DELETE_ORDER_MADE_BY_GUEST_USERS_REQUEST,
  DELETE_ORDER_MADE_BY_GUEST_USERS_SUCCESS,
  DELETE_ORDER_MADE_BY_GUEST_USERS_FAILURE,
  UPDATE_ORDER_MADE_BY_GUEST_USERS_REQUEST,
  UPDATE_ORDER_MADE_BY_GUEST_USERS_SUCCESS,
  UPDATE_ORDER_MADE_BY_GUEST_USERS_FAILURE,
} from "../../constants/OrderMadeByGuestUsers.js";

export const fetchOrderMadeByGuestUsersRequest = (
  startDate,
  endDate,
  agency,
  department
) => {
  return {
    type: FETCH_ORDER_MADE_BY_GUEST_USERS_REQUEST,
    payload: {
      startDate,
      endDate,
      agency,
      department,
    },
  };
};

export const fetchOrderMadeByGuestUsersSuccess = (users) => {
  return {
    type: FETCH_ORDER_MADE_BY_GUEST_USERS_SUCCESS,
    payload: users,
  };
};

export const fetchOrderMadeByGuestUsersFailure = (error) => {
  return {
    type: FETCH_ORDER_MADE_BY_GUEST_USERS_FAILURE,
    payload: error,
  };
};

export const setOrderMadeByGuestUsersValidationError = (validationErrors) => {
  return {
    type: SET_ORDER_MADE_BY_GUEST_USRS_VALIDATION_ERROR,
    payload: validationErrors,
  };
};

export const showOrderMadeByGuestUsersEditForm = () => {
  return {
    type: SHOW_ORDER_MADE_BY_GUEST_USERS_EDIT_FORM,
  };
};

export const deleteOrderMadeByGuestUsersRequest = () => {
  return {
    type: DELETE_ORDER_MADE_BY_GUEST_USERS_REQUEST,
  };
};

export const deleteOrderMadeByGuestUsersSucess = (user) => {
  return {
    type: DELETE_ORDER_MADE_BY_GUEST_USERS_SUCCESS,
    payload: user,
  };
};

export const deleteOrderMadeByGuestUsersFailure = (error) => {
  return {
    type: DELETE_ORDER_MADE_BY_GUEST_USERS_FAILURE,
    payload: error,
  };
};

export const updateOrderMadeByGuestUsersRequest = (payload) => {
  return {
    type: UPDATE_ORDER_MADE_BY_GUEST_USERS_REQUEST,
    payload: payload,
  };
};

export const updateOrderMadeByGuestUsersSuccess = (user) => {
  return {
    type: UPDATE_ORDER_MADE_BY_GUEST_USERS_SUCCESS,
    payload: user,
  };
};

export const updateOrderMadeByGuestUsersFailure = (error) => {
  return {
    type: UPDATE_ORDER_MADE_BY_GUEST_USERS_FAILURE,
    payload: error,
  };
};
