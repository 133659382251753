import fetch from "auth/FetchInterceptor";

const OrderMadeByGuestUsersService = {};

OrderMadeByGuestUsersService.get = function (payload) {
  return fetch({
    url:
      "/reports/patientresult-guest?startdate=" +
      payload.startDate +
      "&enddate=" +
      payload.endDate +
      "&agency=" +
      payload.agency +
      "&department=" +
      payload.department,
    method: "get",
  });
};

OrderMadeByGuestUsersService.update = function (id, data) {
  return fetch({
    url: `/OrderMadeByGuestUsers/${id}`,
    method: "patch",
    data,
  });
};

OrderMadeByGuestUsersService.delete = function (id) {
  return fetch({
    url: `/OrderMadeByGuestUsers/${id}`,
    method: "delete",
  });
};

export default OrderMadeByGuestUsersService;
