import {
  FETCH_EQUIPMENT_SUCCESS,
  FETCH_EQUIPMENT_REQUEST,
  FETCH_EQUIPMENT_FAILURE,
  UPDATE_EQUIPMENT_REQUEST,
  UPDATE_EQUIPMENT_SUCCESS,
  UPDATE_EQUIPMENT_FAILURE,
  SHOW_EQUIPMENT_EDIT_FORM,
  DELETE_EQUIPMENT_REQUEST,
  DELETE_EQUIPMENT_SUCCESS,
  DELETE_EQUIPMENT_FAILURE,
  SET_EQUIPMENT_VALIDATION_ERRORS,
  SHOW_EQUIPMENT_NEW_FORM,
  CREATE_EQUIPMENT_REQUEST,
  CREATE_EQUIPMENT_SUCCESS,
  CREATE_EQUIPMENT_FAILURE,
} from "constants/Equipment";

import {
  addEquipment,
  formtValidationErrors,
  removeEquipment,
  updateEquipment,
} from "utils/Equipment";

const initState = {
  loading: false,
  updating: false,
  creating: false,
  list: [],
  errorMessage: "",
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const machine = (state = initState, action) => {
  switch (action.type) {
    case FETCH_EQUIPMENT_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_EQUIPMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_EQUIPMENT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_EQUIPMENT_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_EQUIPMENT_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_EQUIPMENT_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_EQUIPMENT_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateEquipment(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_EQUIPMENT_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_EQUIPMENT_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeEquipment(state, action),
      };
    case DELETE_EQUIPMENT_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_EQUIPMENT_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addEquipment(state, action),
      };
    case CREATE_EQUIPMENT_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default machine;
