export const SIGNIN = 'SIGNIN'; 
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const AUTH_TOKEN = 'auth_token'
export const CURRENT_USER = 'current_user'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'


//
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST = 'SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS = 'SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE = 'SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILURE';

export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_REQUEST = 'SIGN_UP_WITH_EMAIL_AND_PASSWORD_REQUEST';
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_SUCCESS = 'SIGN_UP_WITH_EMAIL_AND_PASSWORD_SUCCESS';
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILURE = 'SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILURE';
