export const SHOW_CHECK_LIST_NEW_FORM = "SHOW_CHECK_LIST_NEW_FORM";
export const SHOW_CHECK_LIST_EDIT_FORM = "SHOW_CHECK_LIST_EDIT_FORM";
export const SET_CHECK_LIST_VALIDATION_ERRORS = "SET_CHECK_LIST_VALIDATION_ERRORS";

export const FETCH_CHECK_LISTS_REQUEST = "FETCH_CHECK_LISTS_REQUEST";
export const FETCH_CHECK_LISTS_SUCCESS = "FETCH_CHECK_LISTS_SUCCESS";
export const FETCH_CHECK_LISTS_FAILURE = "FETCH_CHECK_LISTS_FAILURE";

export const UPDATE_CHECK_LIST_REQUEST = "UPDATE_CHECK_LIST_REQUEST";
export const UPDATE_CHECK_LIST_SUCCESS = "UPDATE_CHECK_LIST_SUCCESS";
export const UPDATE_CHECK_LIST_FAILURE = "UPDATE_CHECK_LIST_FAILURE";

export const DELETE_CHECK_LIST_REQUEST = "DELETE_CHECK_LIST_REQUEST";
export const DELETE_CHECK_LIST_SUCCESS = "DELETE_CHECK_LIST_SUCCESS";
export const DELETE_CHECK_LIST_FAILURE = "DELETE_CHECK_LIST_FAILURE";

export const CREATE_CHECK_LIST_REQUEST = "CREATE_CHECK_LIST_REQUEST";
export const CREATE_CHECK_LIST_SUCCESS = "CREATE_CHECK_LIST_SUCCESS";
export const CREATE_CHECK_LIST_FAILURE = "CREATE_CHECK_LIST_FAILURE";
