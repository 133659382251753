import {
  DELETE_MAINTENANCE_FAILURE,
  DELETE_MAINTENANCE_REQUEST,
  DELETE_MAINTENANCE_SUCCESS,
  FETCH_MAINTENANCES_FAILURE,
  FETCH_MAINTENANCES_REQUEST,
  FETCH_MAINTENANCES_SUCCESS,
  CREATE_MAINTENANCE_FAILURE,
  CREATE_MAINTENANCE_REQUEST,
  CREATE_MAINTENANCE_SUCCESS,
  SHOW_MAINTENANCE_NEW_FORM,
  SET_MAINTENANCE_VALIDATION_ERRORS,
} from "constants/Maintenance";

export const fetchMaintenancesRequest = () => ({
  type: FETCH_MAINTENANCES_REQUEST,
});

export const fetchMaintenancesSuccess = (maintenances) => ({
  type: FETCH_MAINTENANCES_SUCCESS,
  payload: maintenances,
});

export const fetchMaintenancesFailure = (error) => ({
  type: FETCH_MAINTENANCES_FAILURE,
  payload: error,
});

export const setMaintenanceValidationErrors = (validationErrors) => ({
  type: SET_MAINTENANCE_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleMaintenanceNewForm = () => ({
  type: SHOW_MAINTENANCE_NEW_FORM,
});

export const deleteMaintenanceRequest = (id) => ({
  type: DELETE_MAINTENANCE_REQUEST,
  payload: id,
});

export const deleteMaintenanceSuccess = (maintenance) => ({
  type: DELETE_MAINTENANCE_SUCCESS,
  payload: maintenance,
});

export const deleteMaintenanceFailure = (error) => ({
  type: DELETE_MAINTENANCE_FAILURE,
  payload: error,
});

export const createMaintenanceRequest = (maintenance) => ({
  type: CREATE_MAINTENANCE_REQUEST,
  payload: maintenance,
});

export const createMaintenanceSuccess = (maintenance) => ({
  type: CREATE_MAINTENANCE_SUCCESS,
  payload: maintenance,
});

export const createMaintenanceFailure = (error) => ({
  type: CREATE_MAINTENANCE_FAILURE,
  payload: error,
});
