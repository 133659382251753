import {
  FETCH_DEPARTMENTS_SUCCESS,
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_FAILURE,
  UPDATE_DEPARTMENT_REQUEST,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAILURE,
  SHOW_DEPARTMENT_EDIT_FORM,
  DELETE_DEPARTMENT_REQUEST,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_FAILURE,
  SET_DEPARTMENT_VALIDATION_ERRORS,
  SHOW_DEPARTMENT_NEW_FORM,
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
} from "constants/Department";

import {
  addDepartment,
  formtValidationErrors,
  removeDepartment,
  updateDepartment,
} from "utils/Department";

const initState = {
  loading: false,
  updating: false,
  creating: false,
  list: [],
  errorMessage: "",
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const department = (state = initState, action) => {
  switch (action.type) {
    case FETCH_DEPARTMENTS_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_DEPARTMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_DEPARTMENT_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_DEPARTMENT_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_DEPARTMENT_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_DEPARTMENT_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateDepartment(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_DEPARTMENT_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_DEPARTMENT_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeDepartment(state, action),
      };
    case DELETE_DEPARTMENT_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_DEPARTMENT_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addDepartment(state, action),
      };
    case CREATE_DEPARTMENT_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default department;
