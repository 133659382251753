import _ from "lodash";

export const addBranchTestCapacity = (state, action) => {
  let list = state.list;
  const savedBranchTestCapacity = action.payload;

  return [savedBranchTestCapacity, ...list];
};

export const updateBranchTestCapacity = (state, action) => {
  let list = state.list;
  const updatedBranchTestCapacity = action.payload;

  const index = _.findIndex(list, ["id", updatedBranchTestCapacity.id]);
  list[index] = updatedBranchTestCapacity;

  return [...list];
};


export const removeBranchTestCapacity = (state, action) => {
  let list = state.list;
  const deletedBranchTestCapacityId = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedBranchTestCapacityId;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};
