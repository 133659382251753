import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
    FETCH_APPROVED_ORDER_REQUEST,
    UPDATE_APPROVED_ORDER_REQUEST,
    FETCH_APPROVED_ORDER_OBX_REQUEST
} from "constants/OrderStatus/ApprovedOrders";
import {
  fetchApprovedOrderFailure,
  fetchApprovedOrderSuccess,
  updateApprovedOrdersFailure,
  updateApprovedOrdersSuccess,
  fetchApprovedOrderObxFailure,
  fetchApprovedOrderObxSuccess
} from "redux/actions/OrderStatus/ApprovedOrders";

import ApprovedOrders from "services/OrderStatus/ApprovedOrdres";

export function* onFetchApprovedOrders() {
  yield takeLatest(FETCH_APPROVED_ORDER_REQUEST, function* (payload) {
    try {
      const result = yield call(ApprovedOrders.get, payload.payload);
      if (result.status) {
        yield put(fetchApprovedOrderSuccess(result.data));
      } else {
        yield put(fetchApprovedOrderFailure(result.message));
      }
    } catch (error) {
      yield put(fetchApprovedOrderFailure(error.message));
    }
  });
}

export function* onFetchApprovedOrderObx() {
  yield takeLatest(FETCH_APPROVED_ORDER_OBX_REQUEST, function* (payload) {
    try {
      const result = yield call(ApprovedOrders.getObx, payload.payload.visitUuid);
      if (result.status) {
        yield put(fetchApprovedOrderObxSuccess(result.data));
      } else {
        yield put(fetchApprovedOrderObxFailure(result.message));
      }
    } catch (error) {
      yield put(fetchApprovedOrderObxFailure(error.message));
    }
  });
}

export function* onUpdateApprovedOrders() {
  yield takeLatest(UPDATE_APPROVED_ORDER_REQUEST, function* ( payload ) {
    try {
      const result = yield call(
        ApprovedOrders.update,
        payload.payload
      );
      if (result.status) {
        message.success("Discrepancy Status Changed");
        yield put(updateApprovedOrdersSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        // yield put(setDiscrepancyValidationErrors(result.data.errors));
        yield put( updateApprovedOrdersFailure(result.message ));
      } else {
        yield put( updateApprovedOrdersFailure,(result.message ));
      }
    } catch (error) {
      yield put(  updateApprovedOrdersFailure, ( error.message ));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchApprovedOrders),
    fork(onFetchApprovedOrderObx),
    fork(onUpdateApprovedOrders),
  ]);
}
