import {
  FETCH_TOP_PANELS_REQUEST,
  FETCH_TOP_PANELS_FAILURE,
  FETCH_TOP_PANELS_SUCCESS,
} from "constants/TopPanels";

export const fetchTopPanelsRequest = (payload) => {
  return {
    type: FETCH_TOP_PANELS_REQUEST,
    payload: payload,
  };
};

export const fetchTopPanelsSuccess = (payload) => {
  return {
    type: FETCH_TOP_PANELS_SUCCESS,
    payload: payload,
  };
};

export const fetchTopPanelsFailure = (payload) => {
  return {
    type: FETCH_TOP_PANELS_FAILURE,
    payload: payload,
  };
};
