import _ from "lodash";

export const addClientCompany = (state, action) => {
  let list = state.list;
  const savedClientCompany = action.payload;

  return [savedClientCompany, ...list];
};

export const updateClientCompany = (state, action) => {
  let list = state.list;
  const updatedClientCompany = action.payload;

  const index = _.findIndex(list, ["id", updatedClientCompany.id]);
  list[index] = updatedClientCompany;

  return [...list];
};

export const removeClientCompany = (state, action) => {
  let list = state.list;
  const deletedClientCompany = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedClientCompany.id;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};
