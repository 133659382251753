import {
    TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_REQUEST,
    TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_SUCCESS,
    TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_FAILURE,
    SET_TOP_BRANCH_FOR_WALKING_CUSTOMERS_VALIDATION_ERROR
} from "../../constants/TopBranchesForWalkInCustomer"

const initState ={
    loading : false,
    list : [],
    errorMessage : ""
}

const topBranchesForWalkinCustomers = (state = initState, action) =>{
     switch(action.type)
     {
         case  TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_REQUEST : 
            return {
                ...state,
                loading : true,
                payload : action.payload
            }

         case TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_SUCCESS : 
            return {
                ...state,
                loading : false,
                list : action.payload
            }

         case TOP_BRANCH_FOR_WALKIN_CUSTOMERS_FETCH_FAILURE : 
            return{ 
                ...state,
                payload : action.payload
            }

         case SET_TOP_BRANCH_FOR_WALKING_CUSTOMERS_VALIDATION_ERROR : 
            return{
                ...state,
                loading : false,
                errorMessage : action.payload
            }

         default :
            return state;
     }

}

export default topBranchesForWalkinCustomers;


