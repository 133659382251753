import { 
    FETCH_AGENCY_TEST_FAILURE,
    FETCH_AGENCY_TEST_REQUEST,
    FETCH_AGENCY_TEST_SUCCESS,
    CREATE_AGENCY_TEST_FAILURE,
    CREATE_AGENCY_TEST_SUCCESS,
    CREATE_AGENCY_TEST_REQUEST,
    UPDATE_AGENCY_TEST_REQUEST,
    UPDATE_AGENCY_TEST_FAILURE,
    UPDATE_AGENCY_TEST_SUCCESS
} from "constants/AgencyTests"

export const fetchAgencyTestRequest = (agencyName) =>{
    return {
        type : FETCH_AGENCY_TEST_REQUEST,
        payload : agencyName
    }
}

export const fetchAgencyTestSuccess = ( payload ) =>{
     return{ 
         type : FETCH_AGENCY_TEST_SUCCESS,
         payload : payload
     }
}

export const fetchAgencyTestFailure = ( payload ) =>{ 
    return { 
        type : FETCH_AGENCY_TEST_FAILURE,
        payload : payload
    }
}

export const createAgencyTestRequest = (payload) =>{
    return {
        type : CREATE_AGENCY_TEST_REQUEST,
        payload : payload
    }
}

export const createAgencyTestFailure = ( payload ) =>{
     return{ 
         type : CREATE_AGENCY_TEST_FAILURE,
         payload : payload
     }
}

export const createAgencyTestSuccess = ( payload ) =>{ 
    return { 
        type : CREATE_AGENCY_TEST_SUCCESS,
        payload : payload
    }
}

export const updateAgencyTestRequest = (payload) =>{
    return {
        type : UPDATE_AGENCY_TEST_REQUEST,
        payload : payload
    }
}

export const updateAgencyTestFailure = ( payload ) =>{
     return{ 
         type : UPDATE_AGENCY_TEST_FAILURE,
         payload : payload
     }
}

export const updateAgencyTestSuccess = ( payload ) =>{ 
    return { 
        type : UPDATE_AGENCY_TEST_SUCCESS,
        payload : payload
    }
}