import {
  DELETE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE,
  DELETE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST,
  DELETE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS,
  FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_FAILURE,
  FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_REQUEST,
  FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_SUCCESS,
  CREATE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE,
  CREATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST,
  CREATE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS,
  SHOW_CLIENT_COMPANY_TEST_DIRECTORY_EDIT_FORM,
  SHOW_CLIENT_COMPANY_TEST_DIRECTORY_NEW_FORM,
  UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE,
  UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST,
  UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS,
  SET_CLIENT_COMPANY_TEST_DIRECTORY_VALIDATION_ERRORS,
} from "constants/ClientCompanyTestDirectory";

export const fetchClientCompanyTestDirectoriesRequest = () => ({
  type: FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_REQUEST,
});

export const fetchClientCompanyTestDirectoriesSuccess = (clientCompanyTestDirectories) => ({
  type: FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_SUCCESS,
  payload: clientCompanyTestDirectories,
});

export const fetchClientCompanyTestDirectoriesFailure = (error) => ({
  type: FETCH_CLIENT_COMPANY_TEST_DIRECTORIES_FAILURE,
  payload: error,
});

export const setClientCompanyTestDirectoryValidationErrors = (validationErrors) => ({
  type: SET_CLIENT_COMPANY_TEST_DIRECTORY_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleClientCompanyTestDirectoryEditForm = () => ({
  type: SHOW_CLIENT_COMPANY_TEST_DIRECTORY_EDIT_FORM,
});

export const toggleClientCompanyTestDirectoryNewForm = () => ({
  type: SHOW_CLIENT_COMPANY_TEST_DIRECTORY_NEW_FORM,
});

export const updateClientCompanyTestDirectoryRequest = (clientCompanyTestDirectory) => ({
  type: UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST,
  payload: clientCompanyTestDirectory,
});

export const updateClientCompanyTestDirectorySuccess = (clientCompanyTestDirectory) => ({
  type: UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS,
  payload: clientCompanyTestDirectory,
});

export const updateClientCompanyTestDirectoryFailure = (error) => ({
  type: UPDATE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE,
  payload: error,
});

export const deleteClientCompanyTestDirectoryRequest = (id) => ({
  type: DELETE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST,
  payload: id,
});

export const deleteClientCompanyTestDirectorySuccess = (clientCompanyTestDirectory) => ({
  type: DELETE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS,
  payload: clientCompanyTestDirectory,
});

export const deleteClientCompanyTestDirectoryFailure = (error) => ({
  type: DELETE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE,
  payload: error,
});

export const createClientCompanyTestDirectoryRequest = (clientCompanyTestDirectory) => ({
  type: CREATE_CLIENT_COMPANY_TEST_DIRECTORY_REQUEST,
  payload: clientCompanyTestDirectory,
});

export const createClientCompanyTestDirectorySuccess = (clientCompanyTestDirectory) => ({
  type: CREATE_CLIENT_COMPANY_TEST_DIRECTORY_SUCCESS,
  payload: clientCompanyTestDirectory,
});

export const createClientCompanyTestDirectoryFailure = (error) => ({
  type: CREATE_CLIENT_COMPANY_TEST_DIRECTORY_FAILURE,
  payload: error,
});
