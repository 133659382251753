import { CREATE_AGENCIES_TESTS, UPDATE_AGENCIES_TESTS } from "configs/UserPermissions";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import { message } from "antd";
import { FETCH_AGENCY_TEST_REQUEST } from "constants/AgencyTests";
import {
    all,
    put,
    fork,
    call,
    takeLatest,
  } from "redux-saga/effects";
import { createAgencyTestFailure, createAgencyTestSuccess, fetchAgencyTestFailure, fetchAgencyTestSuccess, updateAgencyTestFailure, updateAgencyTestSuccess } from "redux/actions";

import AgencyTests from "services/AgencyTests"

export function* onFetchAgencyTest() {
    yield takeLatest(FETCH_AGENCY_TEST_REQUEST, function* (payload) {
      try {
        const result = yield call(AgencyTests.get, payload.payload); //where payload.payload returns me the agency name that is currently selected agencyname
        if (result.status) {
          yield put(fetchAgencyTestSuccess(result.data));
        } else {
          yield put(fetchAgencyTestFailure(result.message));
        }
      } catch (error) {
        yield put(fetchAgencyTestFailure(error.message));
      }
    });
  }

  export function* onCreateAgencyTest() {
    yield takeLatest(CREATE_AGENCIES_TESTS, function* ( payload ) {
      try {
        const result = yield call(AgencyTests.create, payload.payload);
        if (result.status) {
          message.success("Created Successfully");
          yield put(createAgencyTestSuccess(result.data));
        } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
            message.error("Unable to create test");
          yield put(createAgencyTestFailure(result.data.errors));
        } else {
          yield put(createAgencyTestFailure(result.message));
          message.error("Unable to create test");
        }
      } catch (error) {
        yield put(createAgencyTestFailure(error.message));
        message.error("Unable to create test");
      }
    });
  }

export function* onUpdateAgencyTest() {
    yield takeLatest(UPDATE_AGENCIES_TESTS, function* ( payload ) {
      try {
        const result = yield call(
          AgencyTests.update,
          payload
        );
        if (result.status) {
          message.success("Successfully Updated");
          yield put(updateAgencyTestSuccess(result.data));
        } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
          message.error("Unable to update your data");
          yield put(updateAgencyTestFailure(result.data.errors));
        } else {
            message.error("Unable to update your data");
          yield put(updateAgencyTestFailure(result.message));
        }
      } catch (error) {
        message.error("Unable to update your data");
        yield put(updateAgencyTestFailure(error.message));
      }
    });
  }

  export default function* rootSaga() {
    yield all([
      fork(onCreateAgencyTest),
      fork(onFetchAgencyTest),
      fork(onUpdateAgencyTest),
    ]);
  }