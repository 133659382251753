import fetch from "auth/FetchInterceptor";

const PatientResultService = {};

PatientResultService.index = function (payload) {
  return fetch({
    url:
      "/reports/patientresult-all?" +
      "startdate=" +
      payload.startDate +
      "&" +
      "enddate=" +
      payload.endDate +
      "&" +
      "agency=null&department=null",
    method: "get",
  });
};

export default PatientResultService;
