import {
  ADD_TEST_DIRECTORY,
  FETCH_APPOINTMENT_METADATA_FAILURE,
  FETCH_APPOINTMENT_METADATA_REQUEST,
  FETCH_APPOINTMENT_METADATA_SUCCESS,
  SAVE_TEST_REGISTRATION_REQUEST,
  SAVE_TEST_REGISTRATION_SUCCESS,
  ADD_TEST_CATEGORY,
  SET_NUMBER_OF_PATIENT,
  ADD_PATIENT,
  REMOVE_PATIENT,
  SET_APPOINTMENT_DATE,
  SET_APPOINTMENT_TIME,
  SET_BRANCH_ID,
  SET_APPOINTMENT_DAY_NAME,
  CLEAR_APPOINTMENT_TIME,
  SET_PAYMENT_METHOD,
} from "constants/Appointment";

export const fetchAppointmentMetadataRequest = () => ({
  type: FETCH_APPOINTMENT_METADATA_REQUEST,
});

export const fetchAppointmentMetadataSuccess = (metadata) => ({
  type: FETCH_APPOINTMENT_METADATA_SUCCESS,
  payload: metadata,
});

export const fetchAppointmentMetadataFailure = () => ({
  type: FETCH_APPOINTMENT_METADATA_FAILURE,
});

export const addTestDirectory = (id) => ({
  type: ADD_TEST_DIRECTORY,
  payload: id,
});

export const addTestCategory = (testCategory) => ({
  type: ADD_TEST_CATEGORY,
  payload: testCategory,
});

export const saveTestRegistrationsRequest = (testRegistrations) => ({
  type: SAVE_TEST_REGISTRATION_REQUEST,
  payload: testRegistrations,
});

export const saveTestRegistrationsSuccess = () => ({
  type: SAVE_TEST_REGISTRATION_SUCCESS,
});

export const saveTestRegistrationsFailure = (error) => ({
  type: SAVE_TEST_REGISTRATION_SUCCESS,
  payload: error,
});

export const setNumberOfPatient = (number) => ({
  type: SET_NUMBER_OF_PATIENT,
  payload: number,
});

export const addPatient = (patient) => ({
  type: ADD_PATIENT,
  payload: patient,
});

export const removePatient = (id) => ({
  type: REMOVE_PATIENT,
  payload: id,
});

export const setAppointmentDate = (appointmentDate) => ({
  type: SET_APPOINTMENT_DATE,
  payload: appointmentDate,
});

export const setAppointmentDayName = (appointmentDayName) => ({
  type: SET_APPOINTMENT_DAY_NAME,
  payload: appointmentDayName,
});

export const setAppointmentTime = (appointmentTime) => ({
  type: SET_APPOINTMENT_TIME,
  payload: appointmentTime,
});

export const setPaymentMethod = (paymentMethod) => ({
  type: SET_PAYMENT_METHOD,
  payload: paymentMethod,
});

export const clearAppointmentTime = () => ({
  type: CLEAR_APPOINTMENT_TIME,
});

export const setBranchId = (branchID) => ({
  type: SET_BRANCH_ID,
  payload: branchID,
});
