import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_REQUEST } from "../../constants/OMBGUSampleNotRecievedByIcl";
import {
  fetchOMBGUSampleNotRecievedSuccess,
  fetchOMBGUSampleNotRecievedFailure,
} from "../actions/OMBGUSampleNotRecievedByIcl";

import OMBGUSampleNotRecievedByIcl from "../../services/OMBGUSampleNotRecievedByIcl";
export function* onFetchOMGBGUSampleNotRecievedByIcl() {
  yield takeLatest(
    FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_REQUEST,
    function* (payload) {
      try {
        const result = yield call(
          OMBGUSampleNotRecievedByIcl.get,
          payload.payload
        );
        if (result.status) {
          yield put(fetchOMBGUSampleNotRecievedSuccess(result.data));
        } else {
          yield put(fetchOMBGUSampleNotRecievedFailure(result.message));
        }
      } catch (error) {
        yield put(fetchOMBGUSampleNotRecievedFailure(error.message));
      }
    }
  );
}

export default function* rootSaga() {
  yield all([fork(onFetchOMGBGUSampleNotRecievedByIcl)]);
}
