import {
  FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_FAILURE,
  FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_REQUEST,
  FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_SUCCESS,
} from "../../constants/OMBGUSampleNotRecievedByIcl";

const initialState = {
  loading: false,
  list: [],
  errorMessage: "",
};

const OMBGUSampleNotRecievedByICL = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };

    case FETCH_OMBGU_SAMPLE_NOT_RECIEVED_BY_ICL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    default:
      return {
        state,
      };
  }
};

export default OMBGUSampleNotRecievedByICL;
