import {
  FETCH_SECTIONS_SUCCESS,
  FETCH_SECTIONS_REQUEST,
  FETCH_SECTIONS_FAILURE,
  UPDATE_SECTION_REQUEST,
  UPDATE_SECTION_SUCCESS,
  UPDATE_SECTION_FAILURE,
  SHOW_SECTION_EDIT_FORM,
  DELETE_SECTION_REQUEST,
  DELETE_SECTION_SUCCESS,
  DELETE_SECTION_FAILURE,
  SET_SECTION_VALIDATION_ERRORS,
  SHOW_SECTION_NEW_FORM,
  CREATE_SECTION_REQUEST,
  CREATE_SECTION_SUCCESS,
  CREATE_SECTION_FAILURE,
} from "constants/Section";

import {
  addSection,
  formtValidationErrors,
  removeSection,
  updateSection,
} from "utils/Section";

const initState = {
  loading: false,
  updating: false,
  creating: false,
  list: [],
  errorMessage: "",
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const section = (state = initState, action) => {
  switch (action.type) {
    case FETCH_SECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        showEditForm: false,
        showNewForm: false,
      };
    case FETCH_SECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_SECTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_SECTION_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_SECTION_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_SECTION_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_SECTION_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_SECTION_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateSection(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_SECTION_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_SECTION_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_SECTION_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeSection(state, action),
      };
    case DELETE_SECTION_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_SECTION_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_SECTION_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addSection(state, action),
      };
    case CREATE_SECTION_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default section;
