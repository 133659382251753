export const SHOW_MAINTENANCE_NEW_FORM = "SHOW_MAINTENANCE_NEW_FORM";
export const SET_MAINTENANCE_VALIDATION_ERRORS = "SET_MAINTENANCE_VALIDATION_ERRORS";

export const FETCH_MAINTENANCES_REQUEST = "FETCH_MAINTENANCES_REQUEST";
export const FETCH_MAINTENANCES_SUCCESS = "FETCH_MAINTENANCES_SUCCESS";
export const FETCH_MAINTENANCES_FAILURE = "FETCH_MAINTENANCES_FAILURE";

export const DELETE_MAINTENANCE_REQUEST = "DELETE_MAINTENANCE_REQUEST";
export const DELETE_MAINTENANCE_SUCCESS = "DELETE_MAINTENANCE_SUCCESS";
export const DELETE_MAINTENANCE_FAILURE = "DELETE_MAINTENANCE_FAILURE";

export const CREATE_MAINTENANCE_REQUEST = "CREATE_MAINTENANCE_REQUEST";
export const CREATE_MAINTENANCE_SUCCESS = "CREATE_MAINTENANCE_SUCCESS";
export const CREATE_MAINTENANCE_FAILURE = "CREATE_MAINTENANCE_FAILURE";
