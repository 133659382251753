import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "ICL";
//export const APP_NAME = 'Emilus';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const QC_PREFIX_PATH = "/qc";
export const AUTH_PREFIX_PATH = "/auth";
export const ADMIN_PREFIX_PATH = "/admin";
export const UPDATE_ACTION = "update";
export const NEW_ACTION = "new";
export const ROLE_SUPER_ADMIN = "super-admin";
export const ROLE_GUEST = "guest";
export const ROLE_QUALITY_CONTROL = "qc-admin";
export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
};

export const UNPROCESSABLE_ENTITY_CODE = 422;
