import moment from "moment";
import {
  DATE_AND_TIME_FOR_TABLE_FORMAT,
} from "../constants/DateTimeUtilConstants";

export const changeDateFormatAndReturnNewDate = (oldDate, newFormat) => {
  const newDate = moment(oldDate).format(newFormat);
  return newDate;
};

export const changeDateToHumanReadableFormat = (list) => {
  const listContainingHumanReadableDate = list.map((singleJson) => {
    const timeRecieved = singleJson.obrDateTimeSpecimenReceived;
    const timeReleased = singleJson.obrDateTimeResultReleasedLatest;
    const timeCollected = singleJson.obrDateTimeSpecimenCollected;

    const formatedTimeRecieved =
      timeRecieved.slice(0, 8) +
      "T" +
      timeRecieved.slice(8, timeRecieved.length);
    const formatedTimeReleased =
      timeReleased.slice(0, 8) +
      "T" +
      timeReleased.slice(8, timeReleased.length);
    const formatedTimeCollected =
      timeCollected.slice(0, 8) +
      "T" +
      timeCollected.slice(8, timeCollected.length);

    singleJson.obrDateTimeSpecimenReceived = changeDateFormatAndReturnNewDate(
      formatedTimeRecieved,
      DATE_AND_TIME_FOR_TABLE_FORMAT
    );
    singleJson.obrDateTimeResultReleasedLatest =
      changeDateFormatAndReturnNewDate(
        formatedTimeReleased,
        DATE_AND_TIME_FOR_TABLE_FORMAT
      );
    singleJson.obrDateTimeSpecimenCollected = changeDateFormatAndReturnNewDate(
      formatedTimeCollected,
      DATE_AND_TIME_FOR_TABLE_FORMAT
    );
    return singleJson;
  });
  return listContainingHumanReadableDate;
};

export const changeFormatedDatetoHumanReadableFormat = (list) =>{
  const listContainingHumanReadableDate = list.map(singleJson =>{
    singleJson.webAgeOrDateOfBirth = changeDateFormatAndReturnNewDate( singleJson.webAgeOrDateOfBirth, DATE_AND_TIME_FOR_TABLE_FORMAT);
    return singleJson;
  });
  return listContainingHumanReadableDate;
}