import {
  DELETE_COUPON_FAILURE,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  FETCH_COUPONS_FAILURE,
  FETCH_COUPONS_REQUEST,
  FETCH_COUPONS_SUCCESS,
  CREATE_COUPON_FAILURE,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  SHOW_COUPON_EDIT_FORM,
  SHOW_COUPON_NEW_FORM,
  UPDATE_COUPON_FAILURE,
  UPDATE_COUPON_REQUEST,
  UPDATE_COUPON_SUCCESS,
  SET_COUPON_VALIDATION_ERRORS,
  PRINT_COUPONS_REQUEST,
  PRINT_COUPONS_SUCCESS,
  PRINT_COUPONS_FAILURE,
  USE_COUPON_REQUEST,
  USE_COUPON_SUCCESS,
  USE_COUPON_FAILURE,
} from "constants/Coupon";

export const fetchCouponsRequest = () => ({
  type: FETCH_COUPONS_REQUEST,
});

export const fetchCouponsSuccess = (coupons) => ({
  type: FETCH_COUPONS_SUCCESS,
  payload: coupons,
});

export const fetchCouponsFailure = (error) => ({
  type: FETCH_COUPONS_FAILURE,
  payload: error,
});

export const setCouponValidationErrors = (validationErrors) => ({
  type: SET_COUPON_VALIDATION_ERRORS,
  payload: validationErrors,
});

export const toggleCouponEditForm = () => ({
  type: SHOW_COUPON_EDIT_FORM,
});

export const toggleCouponNewForm = () => ({
  type: SHOW_COUPON_NEW_FORM,
});

export const updateCouponRequest = (coupon) => ({
  type: UPDATE_COUPON_REQUEST,
  payload: coupon,
});

export const updateCouponSuccess = (coupon) => ({
  type: UPDATE_COUPON_SUCCESS,
  payload: coupon,
});

export const updateCouponFailure = (error) => ({
  type: UPDATE_COUPON_FAILURE,
  payload: error,
});

export const deleteCouponRequest = (id) => ({
  type: DELETE_COUPON_REQUEST,
  payload: id,
});

export const deleteCouponSuccess = (coupon) => ({
  type: DELETE_COUPON_SUCCESS,
  payload: coupon,
});

export const deleteCouponFailure = (error) => ({
  type: DELETE_COUPON_FAILURE,
  payload: error,
});

export const createCouponRequest = (coupon) => ({
  type: CREATE_COUPON_REQUEST,
  payload: coupon,
});

export const createCouponSuccess = (coupons) => ({
  type: CREATE_COUPON_SUCCESS,
  payload: coupons,
});

export const createCouponFailure = (error) => ({
  type: CREATE_COUPON_FAILURE,
  payload: error,
});

export const printCouponRequest = (ids) => ({
  type: PRINT_COUPONS_REQUEST,
  payload: ids,
});

export const printCouponSuccess = (coupons) => ({
  type: PRINT_COUPONS_SUCCESS,
  payload: coupons,
});

export const printCouponFailure = (error) => ({
  type: PRINT_COUPONS_FAILURE,
  payload: error,
});

export const usageCouponRequest = (coupon) => ({
  type: USE_COUPON_REQUEST,
  payload: coupon,
});

export const useCouponSuccess = (coupon) => ({
  type: USE_COUPON_SUCCESS,
  payload: coupon,
});

export const useCouponFailure = (error) => ({
  type: USE_COUPON_FAILURE,
  payload: error,
});
