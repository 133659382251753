import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_TOP_TEST_REQUEST
} from "../../constants/TopTests"
import { fetchTopTestFailure, fetchTopTestSuccess, fetchTopTestValidationErrors } from "redux/actions";
import TopTestsService from "../../services/TopTests";

export function* onFetchTopTestsRequest() {
    yield takeLatest(FETCH_TOP_TEST_REQUEST, function* ({payload}) {
      try {
        const result = yield call(TopTestsService.get, payload);
        if (result.status){
         yield put(fetchTopTestSuccess(result.data));
        }
        else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
            yield put(fetchTopTestValidationErrors(result.data.errors));
        }
        else {
          yield put(fetchTopTestFailure(result.message));
        }
      } catch (error) {
        yield put(fetchTopTestFailure(error.message));
      }
    });
  }
  

  export default function* rootSaga() {
    yield all([
      fork(onFetchTopTestsRequest),
    ]);
  }