import {
  FETCH_PATIENTTESTRESULTHISTORY_FAILURE,
  FETCH_PATIENTTESTRESULTHISTORY_REQUEST,
  FETCH_PATIENTTESTRESULTHISTORY_SUCCESS,
  SHOW_NEW_ORDER_SEARCH_FORM,
  FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
  FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE,
  FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS
} from "constants/PatientTestResultHistory";

export const fetchPatientTestResultHistoryRequest = (patientid,sampleid) => ({
  type: FETCH_PATIENTTESTRESULTHISTORY_REQUEST,
  payload:{
    patientid,sampleid
  }
});

export const fetchPatientTestResultHistorySuccess = (patienttestresulthistory) => ({
  type: FETCH_PATIENTTESTRESULTHISTORY_SUCCESS,
  payload: patienttestresulthistory,
});



export const fetchPatientTestResultHistoryFailure = (error) => ({
  type: FETCH_PATIENTTESTRESULTHISTORY_FAILURE,
  payload: error,
});



export const toggleNewOrderPatientSearchForm = () => ({
  type: SHOW_NEW_ORDER_SEARCH_FORM,
});

// export const fetchPatientRequest = (phonenumber,sex,patientid,sampleid) => ({
//   type: FETCH_EXISTING_NEW_ORDER_PATIENT_REQUEST,
//   payload:{
//     phonenumber,sex,patientid,sampleid
//   }
// });

// export const fetchPatientSuccess = (Patient) => ({
//   type: FETCH_EXISTING_NEW_ORDER_PATIENT_SUCCESS,
//   payload:  Patient,
// });

// export const fetchPatientFailure = (error) => ({
//   type: FETCH_EXISTING_NEW_ORDER_PATIENT_FAILURE,
//   payload: error,
// });

