import fetch from "auth/FetchInterceptor";

const TopBranchesForWalkInCustomersService = {};

TopBranchesForWalkInCustomersService.get = function (filterBaritems) {
  return fetch({
    url: "/reports/topbranchesforwalkin",
    method: "get",
    data : filterBaritems
  });
};

export default TopBranchesForWalkInCustomersService;
