import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
    FETCH_DECLINED_ORDER_REQUEST,
    UPDATE_DECLINED_ORDER_REQUEST,
    FETCH_DECLINED_ORDER_OBX_REQUEST
} from "constants/OrderStatus/DeclinedOrders";
import {
  fetchDeclinedOrderFailure,
  fetchDeclinedOrderSuccess,
  fetchDeclinedOrderObxFailure,
  fetchDeclinedOrderObxSuccess,
  updateDeclinedOrderFailure,
  updateDeclinedOrderSuccess,
} from "redux/actions/OrderStatus/DeclinedOrders";

import DeclinedOrders from "services/OrderStatus/DeclinedOrders";
export function* onFetchDeclinedOrders() {
  yield takeLatest(FETCH_DECLINED_ORDER_REQUEST, function* (payload) {
    try {
      const result = yield call(DeclinedOrders.get, payload.payload);
      if (result.status) {
        yield put(fetchDeclinedOrderSuccess(result.data));
      } else {
        yield put(fetchDeclinedOrderFailure(result.message));
      }
    } catch (error) {
      yield put(fetchDeclinedOrderFailure(error.message));
    }
  });
}

export function* onFetchDeclinedOrderObx() {
  yield takeLatest(FETCH_DECLINED_ORDER_OBX_REQUEST, function* (payload) {
    try {
      const result = yield call(DeclinedOrders.getObx, payload.payload.visitUuid);
      if (result.status) {
        yield put(fetchDeclinedOrderObxSuccess(result.data));
      } else {
        yield put(fetchDeclinedOrderObxFailure(result.message));
      }
    } catch (error) {
      yield put(fetchDeclinedOrderObxFailure(error.message));
    }
  });
}

export function* onUpdateDeclinedOrders() {
  yield takeLatest(UPDATE_DECLINED_ORDER_REQUEST, function* (payload) {
    try {
      const result = yield call(
        DeclinedOrders.update,
        payload.payload
      );
      if (result.status) {
        message.success("Discrepancy Status Changed");
        yield put(updateDeclinedOrderSuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        // yield put(setDiscrepancyValidationErrors(result.data.errors));
        yield put( updateDeclinedOrderFailure(result.message ));
      } else {
        yield put( updateDeclinedOrderFailure,(result.message ));
      }
    } catch (error) {
      yield put(  updateDeclinedOrderFailure, ( error.message ));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchDeclinedOrders),
    fork(onFetchDeclinedOrderObx),
    fork(onUpdateDeclinedOrders),
  ]);
}
