import _ from "lodash";

export const addClientCompanyTestDirectory = (state, action) => {
  let list = state.list;
  const savedClientCompanyTestDirectory = action.payload;

  return [savedClientCompanyTestDirectory, ...list];
};

export const updateClientCompanyTestDirectory = (state, action) => {
  let list = state.list;
  const updatedClientCompanyTestDirectory = action.payload;

  const index = _.findIndex(list, ["id", updatedClientCompanyTestDirectory.id]);
  list[index] = updatedClientCompanyTestDirectory;

  return [...list];
};

export const removeClientCompanyTestDirectory = (state, action) => {
  let list = state.list;
  const deletedClientCompanyTestDirectory = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedClientCompanyTestDirectory.id;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};
