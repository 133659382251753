import {
  FETCH_RELEASEDS_FAILURE,
  FETCH_RELEASEDS_REQUEST,
  FETCH_RELEASEDS_SUCCESS,
} from "constants/Released";

export const fetchReleasedsRequest = (
  startDate,
  endDate,
  agency,
  department
) => ({
  type: FETCH_RELEASEDS_REQUEST,
  payload: {
    startDate,
    endDate,
    agency,
    department,
  },
});

export const fetchReleasedsSuccess = (releaseds) => ({
  type: FETCH_RELEASEDS_SUCCESS,
  payload: releaseds,
});

export const fetchReleasedsFailure = (error) => ({
  type: FETCH_RELEASEDS_FAILURE,
  payload: error,
});
