import fetch from "auth/FetchInterceptor";

const SectionService = {};

SectionService.index = function () {
  return fetch({
    url: "/sections",
    method: "get",
  });
};

SectionService.create = function (data) {
  return fetch({
    url: "/sections",
    method: "post",
    data,
  });
};

SectionService.update = function (id, data) {
  return fetch({
    url: `/sections/${id}`,
    method: "patch",
    data,
  });
};

SectionService.delete = function (id) {
  return fetch({
    url: `/sections/${id}`,
    method: "delete",
  });
};

export default SectionService;
