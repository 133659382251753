import fetch from "auth/FetchInterceptor";

const PatientTestResultHistoryService = {};

PatientTestResultHistoryService.index = function () {
  //   return fetch({
  //     url:
  //       "/reports/patienttestresulthistory?" +
  //       "startDate=" +
  //       payload.startDate +
  //       "&endDate=" +
  //       payload.endDate +
  //       "&agency=" +
  //       payload.agency +
  //       "&department=" +
  //       payload.department,
  //     method: "get",
  //   });
  // };
};
export default PatientTestResultHistoryService;
