import{ 
    FETCH_PENDING_ORDER_REQUEST,
    FETCH_PENDING_ORDER_SUCCESS,
    FETCH_PENDING_ORDER_FAILURE,
    FETCH_PENDING_ORDER_OBX_REQUEST,
    FETCH_PENDING_ORDER_OBX_SUCCESS,
    FETCH_PENDING_ORDER_OBX_FAILURE,
    UPDATE_PENDING_ORDER_REQUEST,
    UPDATE_PENDING_ORDER_SUCCESS,
    UPDATE_PENDING_ORDER_FAILURE
} from "constants/OrderStatus/PendingOrders"
import { changeFormatedDatetoHumanReadableFormat } from "utils/DateTimeUtil"
import { updateOrder } from "utils/Order"
const initState = {
    loading : false,
    loadingObx : false,
    list : [],
    obxList : [],
    errorMessage : ""
}
const PendingOrders = (state = initState, action)=>{
    switch(action.type)
    {
        case  FETCH_PENDING_ORDER_REQUEST : 
        return { 
           ...state,
           loading : true,
        }
        
        case FETCH_PENDING_ORDER_SUCCESS : 
        return {
            ...state,
            loading : false,
            list :changeFormatedDatetoHumanReadableFormat(action.payload)
        }
        
        case FETCH_PENDING_ORDER_FAILURE : 
         return {
            ...state,
            loading : false,
            errorMessage : action.payload
         }
        
         case  FETCH_PENDING_ORDER_OBX_REQUEST : 
         return { 
            ...state,
            loadingObx : true,
         }
          
         case FETCH_PENDING_ORDER_OBX_SUCCESS : 
          return {
            ...state,
            loadingObx : false,
            obxList :action.payload
         }
         
         case FETCH_PENDING_ORDER_OBX_FAILURE : 
            return {
            ...state,
            loadingObx : false,
            errorMessage : action.payload
         }

        case UPDATE_PENDING_ORDER_REQUEST : 
        return { 
           ...state,
        }
        case UPDATE_PENDING_ORDER_SUCCESS : 
        return { 
            ...state,
            // list :  updateOrder(state, action)
        }
      
        case UPDATE_PENDING_ORDER_FAILURE : 
        return { 
           ...state,
           errorMessage : action.payload
        }

        default : 
        return { 
           state
        }
    }
}

export default PendingOrders