import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  CREATE_CLIENT_COMPANY_REQUEST,
  DELETE_CLIENT_COMPANY_REQUEST,
  FETCH_CLIENT_COMPANIES_REQUEST,
  UPDATE_CLIENT_COMPANY_REQUEST,
} from "constants/ClientCompany";
import {
  createClientCompanyFailure,
  createClientCompanySuccess,
  deleteClientCompanyFailure,
  deleteClientCompanySuccess,
  fetchClientCompaniesFailure,
  fetchClientCompaniesSuccess,
  setClientCompanyValidationErrors,
  updateClientCompanyFailure,
  updateClientCompanySuccess,
} from "redux/actions";
import ClientCompanyService from "services/ClientCompany";

export function* onFetchClientCompanies() {
  yield takeLatest(FETCH_CLIENT_COMPANIES_REQUEST, function* () {
    try {
      const result = yield call(ClientCompanyService.index);
      if (result.status) {
        yield put(fetchClientCompaniesSuccess(result.data));
      } else {
        yield put(fetchClientCompaniesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchClientCompaniesFailure(error.message));
    }
  });
}

export function* onUpdateClientCompany() {
  yield takeLatest(UPDATE_CLIENT_COMPANY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        ClientCompanyService.update,
        payload.id,
        payload
      );
      if (result.status) {
        message.success("Updated");
        yield put(updateClientCompanySuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setClientCompanyValidationErrors(result.data.errors));
        yield put(updateClientCompanyFailure(result.message));
      } else {
        yield put(updateClientCompanyFailure(result.message));
      }
    } catch (error) {
      yield put(updateClientCompanyFailure(error.message));
    }
  });
}

export function* onDeleteClientCompany() {
  yield takeLatest(DELETE_CLIENT_COMPANY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(ClientCompanyService.delete, payload);
      if (result.status) {
        message.success("Deleted");
        yield put(deleteClientCompanySuccess(result.data));
      } else {
        yield put(deleteClientCompanyFailure(result.message));
      }
    } catch (error) {
      yield put(deleteClientCompanyFailure(error.message));
    }
  });
}

export function* onCreateClientCompany() {
  yield takeLatest(CREATE_CLIENT_COMPANY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(ClientCompanyService.create, payload);
      if (result.status) {
        message.success("Created");
        yield put(createClientCompanySuccess(result.data));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        yield put(setClientCompanyValidationErrors(result.data.errors));
        yield put(createClientCompanyFailure(result.message));
      } else {
        yield put(createClientCompanyFailure(result.message));
      }
    } catch (error) {
      yield put(deleteClientCompanyFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchClientCompanies),
    fork(onUpdateClientCompany),
    fork(onDeleteClientCompany),
    fork(onCreateClientCompany),
  ]);
}
