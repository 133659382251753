export const FETCH_AGENCY_TEST_REQUEST = "FETCH_AGENCY_TEST_REQUEST";
export const FETCH_AGENCY_TEST_FAILURE = "FETCH_AGENCY_TEST_FAILURE";
export const FETCH_AGENCY_TEST_SUCCESS = "FETCH_AGENCY_TEST_SUCCESS";

export const CREATE_AGENCY_TEST_REQUEST = "CREATE_AGENCY_TEST_REQUEST";
export const CREATE_AGENCY_TEST_FAILURE = "CREATE_AGENCY_TEST_FAILURE";
export const CREATE_AGENCY_TEST_SUCCESS = "CREATE_AGENCY_TEST_SUCCESS";

export const UPDATE_AGENCY_TEST_REQUEST = "UPDATE_AGENCY_TEST_REQUEST";
export const UPDATE_AGENCY_TEST_FAILURE = "UPDATE_AGENCY_TEST_FAILURE";
export const UPDATE_AGENCY_TEST_SUCCESS = "UPDATE_AGENCY_TEST_SUCCESS";

