import {
  FETCH_NOTYETRELEASED_FAILURE,
  FETCH_NOTYETRELEASED_REQUEST,
  FETCH_NOTYETRELEASED_SUCCESS,  
} from "constants/Notyetreleased";

export const fetchNotyetreleasedRequest = (startDate, endDate, agency, department) => ({
  type: FETCH_NOTYETRELEASED_REQUEST,
  payload : {
     startDate,
     endDate,
     agency,
     department
  }
});

export const fetchNotyetreleasedSuccess = (notyetreleased) => ({
  type: FETCH_NOTYETRELEASED_SUCCESS,
  payload: notyetreleased,
});

export const fetchNotyetreleasedFailure = (error) => ({
  type: FETCH_NOTYETRELEASED_FAILURE,
  payload: error,
});


