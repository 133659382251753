import { FETCH_TOP_TEST_REQUEST, FETCH_TOP_TEST_FAILURE, FETCH_TOP_TEST_SUCCESS, SET_TOP_TEST_VALIDATION_ERRORS } from "constants/TopTests";

const initState = {
  loading : false,
  list : [],
  erroMessage : ""
}

const toptests = (state = initState, action) =>{
  switch(action.type)
  {  
    case FETCH_TOP_TEST_REQUEST :
        return {
             ...state,
             loading : true,
        }

    case FETCH_TOP_TEST_SUCCESS : 
        return{
             ...state,
             loading : false,
             list : action.payload
        }

    case FETCH_TOP_TEST_FAILURE : 
        return{
             ...state,
             loading : false,
             erroMessage : action.payload
        }
    case SET_TOP_TEST_VALIDATION_ERRORS : 
        return{
             ...state,
             erroMessage : action.payload
        }
    default : 
          return initState
  }    
}

export default toptests;